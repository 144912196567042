import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import type { Pictogram } from '../types';
import { COLOR_MAP } from '../colorMap';
import { Svg } from '../../svg';

export const Warning = ({ userColor, variant = 'red' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 200 200" aria-hidden="true" className="light">
      <path
        d="M44 141L100 49L156 141H100H44Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
      <mask
        id="mask0_3952_211"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="44"
        y="49"
        width="113"
        height="92"
      >
        <path d="M44 141L100 49L156 141H100H44Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3952_211)">
        <rect
          x="138"
          y="15"
          width="119.468"
          height="86.4285"
          transform="rotate(59 138 15)"
          fill={
            userColor
              ? userColor && alpha(userColor, 0.6)
              : COLOR_MAP[variant].pictogramTertiary
          }
        />
      </g>
    </Svg>
  );
};
