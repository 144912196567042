import {
  Box,
  Clickable,
  PopoverAnchor,
  PopoverContainer,
  PopoverHeader,
  PopoverRoot,
  TooltipOld,
} from '@mentimeter/ragnar-ui';
import { CopyIcon } from '@mentimeter/ragnar-visuals';
import { useState } from 'react';
import type { MentionPopOver } from '../data-handlers/types';
import { ItemAvatar, ItemEllipsedText, MAX_WIDTH } from './MentionList';

const EMAIL_TRUNCATION_LIMIT = 34;

function truncate(text: string, length: number) {
  return text.length > length ? `${text.slice(0, length)}...` : text;
}

export const MentionDetailsPopover = ({
  showPopover,
  onOpenChange,
  position,
  item,
}: {
  onOpenChange: (value: boolean) => void;
  showPopover: boolean;
  position: DOMRect | null;
  item: MentionPopOver | undefined;
}) => {
  const [copied, setCopied] = useState(false);

  if (!item) return null;

  return (
    <PopoverRoot open={showPopover} onOpenChange={onOpenChange}>
      {position && (
        <PopoverAnchor
          virtualRef={{
            current: {
              getBoundingClientRect: () => position,
            },
          }}
        />
      )}
      <PopoverContainer
        align="start"
        showArrow={false}
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
      >
        <PopoverHeader showDismiss={true} />
        <Box
          maxWidth={MAX_WIDTH}
          alignItems="center"
          flexDirection="row"
          px="space6"
          pb="space6"
          pt="space2"
          width="100%"
        >
          <ItemAvatar item={item} size="large" />
          <Box ml="space2" flex="1 1 auto" my="auto" flexDirection="column">
            <ItemEllipsedText
              id={`suggestion-details-name-${item.id}`}
              text={item.name}
              tooltipDescription={item.name}
              fontWeight="bold"
            />
            <Box flexDirection="row" alignContent="center">
              <Box mr="space2">
                <ItemEllipsedText
                  id={`suggestion-details-email-${item.id}`}
                  text={truncate(item.email, EMAIL_TRUNCATION_LIMIT)}
                  tooltipDescription={item.email}
                  fontSize="75"
                  color="textWeaker"
                />
              </Box>
              <Box>
                {window.isSecureContext && (
                  <Clickable
                    id="copy-email-button"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(item.email)
                        .then(() => setTimeout(() => setCopied(false), 3000));

                      setCopied(!copied);
                    }}
                  >
                    <CopyIcon color="textWeaker" />
                  </Clickable>
                )}

                <TooltipOld
                  referenceId="copy-email-button"
                  description={copied ? 'Email copied' : 'Copy email'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </PopoverContainer>
    </PopoverRoot>
  );
};
