import { Box } from '@mentimeter/ragnar-ui';
import { QfaIndicator as QfaIndicatorIcon } from '@mentimeter/ragnar-visuals';
import { useQfa, useQuestion } from '../../../data-mediation';
import { usePresentationSettings } from '../../../data-mediation/series/usePresentationSettings';
import { Indication } from '../Indication';
import { IndicationNumber } from '../IndicationNumbers';
import { IndicationDot } from './indication-dot';

const IndicationContent = ({ pendingCount }: { pendingCount: number }) => {
  return (
    <>
      <IndicationDot />
      <Indication>
        <IndicationNumber number={pendingCount} />
        <QfaIndicatorIcon size={3} color="text" />
      </Indication>
    </>
  );
};

export const QfaIndicator = () => {
  const { type } = useQuestion();
  const { qaEnablementScope } = usePresentationSettings();
  const isQfaActive = qaEnablementScope === 'all-slides' || type === 'qfa';
  const { ids } = useQfa();
  const pendingCount = ids.unanswered.total;

  if (!isQfaActive) {
    return null;
  }

  return (
    <Box alignItems="center" data-testid="qfa-indicator">
      <IndicationContent pendingCount={pendingCount} />
    </Box>
  );
};
