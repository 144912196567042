// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostDuplicateRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The public key of the original slide deck */
  slideDeckPublicKey: string;
  /** The region of the original slide deck */
  resourceRegion: 'eu' | 'us';
}

export function postDuplicateRequest(
  requestParams: PostDuplicateRequest,
): Request {
  const data = {
    resource_region: toSnakeKeys(requestParams.resourceRegion),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/view-only/${requestParams.slideDeckPublicKey}/duplicate`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
