import { useCommentsStore } from '@mentimeter/comments-from-audience';
import { LIVE_CHAT_ENABLED } from '@mentimeter/compatibility/constants';
import { Box } from '@mentimeter/ragnar-ui';
import { CommentIndicator } from '@mentimeter/ragnar-visuals';
import { usePresentationSettings } from '../../../data-mediation/series/usePresentationSettings';
import { Indication } from '../Indication';
import { IndicationNumber } from '../IndicationNumbers';

const C = () => {
  const awaitingComments = useCommentsStore((state) => state.backlog.length);
  return (
    <Box alignItems="center" data-testid="cfa-indicator">
      <Indication>
        <IndicationNumber number={awaitingComments} />
        <CommentIndicator size={3} color="text" />
      </Indication>
    </Box>
  );
};

export const CfaIndicator = () => {
  const { liveChatMode } = usePresentationSettings();
  const areCommentsEnabled = liveChatMode === LIVE_CHAT_ENABLED;

  if (!areCommentsEnabled) {
    return null;
  }

  return <C />;
};
