import type { Slide as ViewOnlySlide } from '@mentimeter/view-only-schema/api-types-overrides';
import { commonSlidePropertyKeys } from '../../common/constants';

export const viewOnlySlidePropertyKeys = [
  'slideId',
  'speakerNotes',
  'slideAdminKey',
  'slidePublicKey',
  'qrCodeVisibility',
  ...commonSlidePropertyKeys,
] as const satisfies Array<keyof ViewOnlySlide>;
