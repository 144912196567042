import React from 'react';
import { DisabledPresentTooltip } from '@mentimeter/disable-present-ui';
import {
  Box,
  Clickable,
  TooltipOld,
  type ButtonT,
} from '@mentimeter/ragnar-ui';
import { PlaySolidIcon } from '@mentimeter/ragnar-visuals';
import type { FunctionComponent } from 'react';

interface OverviewListItemPlayButtonProps extends Pick<ButtonT, 'mt'> {
  questionId: string;
  presenterName?: string | undefined;
  isSomeonePresenting?: boolean | undefined;
  onStartPresenting?: ((questionId: string) => void) | undefined;
}

export const OverviewListItemPlayButton: FunctionComponent<
  OverviewListItemPlayButtonProps
> = ({
  questionId,
  isSomeonePresenting,
  presenterName,
  onStartPresenting,
  ...layoutProps
}) => {
  return (
    <>
      {isSomeonePresenting ? (
        <DisabledPresentTooltip
          presenterName={presenterName}
          referenceId={`${questionId}-play-icon`}
        />
      ) : null}
      {onStartPresenting && !isSomeonePresenting ? (
        <TooltipOld
          description="This is the slide currently seen in voting. Click to present from here."
          referenceId={`${questionId}-play-icon`}
          placement="right"
        />
      ) : null}
      <Box
        id={`${questionId}-play-icon`}
        aria-hidden
        alignItems="center"
        {...layoutProps}
      >
        <Clickable
          onClick={(e) => {
            e.stopPropagation();
            onStartPresenting?.(questionId);
          }}
          disabled={!onStartPresenting || isSomeonePresenting}
          aria-label="Present from this slide"
        >
          <PlaySolidIcon color="primary" size={0} />
        </Clickable>
      </Box>
    </>
  );
};
