import { useCallback } from 'react';
import {
  Box,
  Clickable,
  ScreenReaderOnly,
  Text,
  TooltipOld,
} from '@mentimeter/ragnar-ui';
import { EyeOffIcon } from '@mentimeter/ragnar-visuals';

interface OverviewListItemIndexProps {
  listItemId: string;
  index: number;
  isSkipped: boolean;
  isMobile: boolean;
  onUnskipClick?: ((id: string) => void) | undefined;
}

export const OverviewListItemIndex = ({
  listItemId,
  index,
  isSkipped,
  isMobile,
  onUnskipClick,
}: OverviewListItemIndexProps) => {
  const handleUnskipClick = useCallback(() => {
    onUnskipClick?.(listItemId);
  }, [onUnskipClick, listItemId]);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="initial"
      gridColumnStart={1}
    >
      <Text
        fontSize="75"
        color="text"
        textAlign="center"
        extend={() => ({
          maxWidth: 'none', // because of font-scaling we want this to be able to overflow
        })}
      >
        {!isSkipped ? (
          <>
            <ScreenReaderOnly>Slide </ScreenReaderOnly> {index + 1}
          </>
        ) : (
          <>
            <ScreenReaderOnly>Skipped slide</ScreenReaderOnly>
            <TooltipOld
              description="This slide will not be shown while presenting. Click to unskip."
              referenceId={`${listItemId}-eye-off-icon`}
            />
            <Box aria-hidden>
              <Clickable
                id={`${listItemId}-eye-off-icon`}
                aria-label="Unskip slide"
                onClick={handleUnskipClick}
              >
                <EyeOffIcon color="text" size={isMobile ? 2 : 1} />
              </Clickable>
            </Box>
          </>
        )}
      </Text>
    </Box>
  );
};
