/* eslint-disable menti-react/filename-convention--jsx */
import { trackUser } from '@api/tracking/client';
import type { InteractiveContentWithStylingVisualization } from '@mentimeter/presentation-schema/api-types-overrides';
import { getLatestPresentationId } from '@mentimeter/presentation-state';
import * as React from 'react';
import { useQuestion, useSlide } from '../../data-mediation/question';
import { useDispatch } from '../dispatcher';
import { usePresentation } from '../presentation';

export interface SlideActions {
  resetResults: (placement: 'Toolbar' | 'Controls') => void;
  toggleCloseVoting: () => void;
  trackCloseVoting: (placement: 'Toolbar' | 'Hotkey C') => void;
  toggleVizUnits: () => void;
  toggleTrends: () => void;
  toggleJoinInstructions: () => void;
  setVisualization: (
    visualization: InteractiveContentWithStylingVisualization['visualization'],
  ) => void;
  deleteResults: () => void;
}

const defaultState: SlideActions = {
  resetResults: () => {},
  trackCloseVoting: () => {},
  toggleCloseVoting: () => {},
  toggleVizUnits: () => {},
  toggleTrends: () => {},
  toggleJoinInstructions: () => {},
  setVisualization: () => {},
  deleteResults: () => {},
};
const context = React.createContext<SlideActions>(defaultState);
context.displayName = 'SlideActionsContext';

const Provider = context.Provider;

export const SlideActionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { showTrends } = usePresentation();

  const { id, active, percent_default, hide_join_instructions } = useQuestion();
  const { slideAdminKey, interactiveContents } = useSlide();

  const resetResults = (placement: 'Toolbar' | 'Controls') => {
    dispatch({
      type: 'RESET_SLIDE',
      payload: { id, placement },
    });
  };

  const deleteResults = () => {
    dispatch({
      type: 'DELETE_SLIDE',
      payload: {
        questionAdminKey: slideAdminKey,
        interactiveSlideId:
          interactiveContents[0]?.interactiveContentId ?? 'not-migrated',
      },
    });
  };

  const trackCloseVoting = (placement: string) =>
    trackUser({
      event: 'Toggled close voting',
      properties: {
        context: 'Presentation view',
        mode: active ? 'Closed' : 'Opened',
        placement,
        'Presentation id': getLatestPresentationId(),
      },
    });

  const toggleCloseVoting = () => {
    dispatch({
      type: 'SET_QUESTION_SETTING',
      payload: {
        id,
        key: 'active',
        value: !active,
      },
    });
  };

  const toggleJoinInstructions = () => {
    dispatch({
      type: 'SET_QUESTION_SETTING',
      payload: {
        id,
        key: 'hide_join_instructions',
        value: !hide_join_instructions,
      },
    });
  };

  const toggleVizUnits = () => {
    dispatch({
      type: 'SET_QUESTION_SETTING',
      payload: { id, key: 'percent_default', value: !percent_default },
    });
  };

  const setVisualization = (
    visualization:
      | InteractiveContentWithStylingVisualization['visualization']
      | undefined
      | null,
  ) => {
    const context = 'Presentation View';
    // TODO: Investigate tracking for visualization
    const tracking = visualization
      ? { layout: visualization, context }
      : { context };

    dispatch({
      type: 'SET_INTERACTIVE_CONTENT_VISAULIZATION',
      payload: { id, key: 'subType', value: visualization },
      meta: {
        tracking,
      },
    });
  };

  const toggleTrends = () => {
    dispatch({
      type: 'SET_PRESENTATION_SETTING',
      payload: { key: 'showTrends', value: !showTrends },
    });
  };

  return (
    <Provider
      value={{
        deleteResults,
        resetResults,
        trackCloseVoting,
        toggleCloseVoting,
        toggleVizUnits,
        toggleTrends,
        toggleJoinInstructions,
        setVisualization,
      }}
    >
      {children}
    </Provider>
  );
};

export const useSlideActions = () => React.useContext(context);

export const TestSlideActionsProvider = ({
  children,
  ...rest
}: React.PropsWithChildren<Partial<SlideActions>>) => {
  return <Provider value={{ ...defaultState, ...rest }}>{children}</Provider>;
};
