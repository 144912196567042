/* eslint-disable menti-react/filename-convention--jsx */
import React, { Suspense } from 'react';
import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import { getValidModuleIdFromString } from './get-valid-module-id';
import { DelayedLoadingPlaceholder } from './DelayedLoadingPlaceholder';

const MODULE_IMPORT_MAP = {
  '100-points': () =>
    import('@mentimeter/question-module-100-points/presentation'),
  big: () => import('@mentimeter/question-module-big/presentation'),
  bullets: () => import('@mentimeter/question-module-bullets/presentation'),
  choices: () => import('@mentimeter/question-module-choices/presentation'),
  deprecated: () =>
    import('@mentimeter/question-module-deprecated/presentation'),
  document: () => import('@mentimeter/question-module-document/presentation'),
  'free-text': () =>
    import('@mentimeter/question-module-free-text/presentation'),
  'google-slides': () =>
    import('@mentimeter/question-module-google-slides/presentation'),
  heading: () => import('@mentimeter/question-module-heading/presentation'),
  image: () => import('@mentimeter/question-module-image/presentation'),
  instructions: () =>
    import('@mentimeter/question-module-instructions/presentation'),
  metadata: () => import('@mentimeter/question-module-metadata/presentation'),
  miro: () => import('@mentimeter/question-module-miro/presentation'),
  number: () => import('@mentimeter/question-module-number/presentation'),
  'numerical-question': () =>
    import('@mentimeter/question-module-numerical-question/presentation'),
  open: () => import('@mentimeter/question-module-open/presentation'),
  paragraph: () => import('@mentimeter/question-module-paragraph/presentation'),
  'pin-on-image': () =>
    import('@mentimeter/question-module-pin-on-image/presentation'),
  powerpoint: () =>
    import('@mentimeter/question-module-powerpoint/presentation'),
  qfa: () => import('@mentimeter/question-module-qfa/presentation'),
  'quiz-choices': () =>
    import('@mentimeter/question-module-quiz-choices/presentation'),
  'quiz-leaderboard': () =>
    import('@mentimeter/question-module-quiz-leaderboard/presentation'),
  'quiz-open': () =>
    import('@mentimeter/question-module-quiz-open/presentation'),
  quote: () => import('@mentimeter/question-module-quote/presentation'),
  ranking: () => import('@mentimeter/question-module-ranking/presentation'),
  rating: () => import('@mentimeter/question-module-rating/presentation'),
  scales: () => import('@mentimeter/question-module-scales/presentation'),
  video: () => import('@mentimeter/question-module-video/presentation'),
  wordcloud: () => import('@mentimeter/question-module-wordcloud/presentation'),
} satisfies Record<
  ValidModuleId,
  () => Promise<{ Presentation: React.ComponentType }>
>;

export const importPresentationDynamically = async (moduleId: ModuleId) => {
  const validModuleId = getValidModuleIdFromString(moduleId);
  const importFn = MODULE_IMPORT_MAP[validModuleId];
  await importFn();
  return;
};

export function importPresentationDynamicallyLazy(moduleId: ModuleId) {
  const validModuleId = getValidModuleIdFromString(moduleId);
  // Evaluate only when it gets called
  const importFn = MODULE_IMPORT_MAP[validModuleId];
  return React.lazy(async () => {
    try {
      const { Presentation } = await importFn();
      return { default: Presentation };
    } catch (error) {
      throw new Error(
        `Question modules: Failed to load Presentation dynamically for moduleId: '${moduleId}'. Error message: ${error}`,
      );
    }
  });
}

export const PresentationModuleSuspense = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <Suspense fallback={<DelayedLoadingPlaceholder />}>{children}</Suspense>
  );
};
