import { Device } from '@mentimeter/ragnar-device';
import { Box, TooltipOld, Text } from '@mentimeter/ragnar-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { EditorErrors } from '@mentimeter/core-global-state';
import { StatusErrorPopover } from './StatusErrorPopover';
import { ERROR_MAP, STATUS_MAP } from './constants';

/**
 * Used to caluclate if the <EditorHeaderStatus> component should be visible or not.
 * Takes into account if an error happened and if so shows the component until a successful save occurrs
 */
const useShowComponent = (
  isUpdatingOrRequesting: boolean,
  error: EditorErrors | undefined,
  resetError: () => void,
) => {
  const [show, setShow] = useState<boolean>(false);
  const [state, setState] = useState<{
    reqStarted: boolean;
    reqComplete: boolean;
    error: EditorErrors | undefined;
  }>({
    reqStarted: false,
    reqComplete: false,
    error: undefined,
  });

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (error) {
      setState((s) => ({ ...s, error }));
      setShow(true);
      if (isUpdatingOrRequesting) {
        setState((s) => ({ ...s, reqStarted: true }));
        resetError();
      }
    } else if (!error && isUpdatingOrRequesting) {
      setState((s) => ({ ...s, error: undefined }));
    }
    if (!error && !state.error && !isUpdatingOrRequesting) {
      timeout = setTimeout(() => setShow(false), 10000);
    }
    return () => {
      /** If the timer is running when hook de-render hide the component immediately instead of waiting for timer to run out */
      if (timeout) {
        setShow(false);
      }
      window.clearTimeout(timeout);
    };
  }, [isUpdatingOrRequesting, error, state.error, resetError]);

  return [show, state.error] as const;
};
const ONE_DAY = 1000 * 60 * 60 * 24;

interface HeaderStatusProps {
  isOffline: boolean;
  isUpdatingOrPersisting: boolean;
  error?: EditorErrors | undefined;
  resetError: () => void;
  lastRequestMs: number;
}

export const HeaderStatus = ({
  isOffline,
  isUpdatingOrPersisting,
  error,
  lastRequestMs,
  resetError,
}: HeaderStatusProps) => {
  const [show, cachedError] = useShowComponent(
    isUpdatingOrPersisting,
    error,
    useCallback(resetError, [resetError]),
  );
  const [showErrorPopover, setShowErrorPopover] = useState<boolean>(false);

  useEffect(() => {
    if (cachedError && !isUpdatingOrPersisting) {
      setShowErrorPopover(true);
    } else if (cachedError && isUpdatingOrPersisting) {
      setShowErrorPopover(false);
    }
  }, [cachedError, isUpdatingOrPersisting]);

  const [hasExpired, setHasExpired] = useState(false);
  const hasExpiredTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (hasExpiredTimeout.current) {
      clearTimeout(hasExpiredTimeout.current);
    }
    hasExpiredTimeout.current = setTimeout(() => {
      hasExpiredTimeout.current = null;
      setHasExpired(true);
    }, ONE_DAY);
  }, [lastRequestMs, setHasExpired]);

  const {
    icon,
    iconBg,
    message,
    errorTitle,
    errorMessage = '',
    errorDescription,
    showIndication,
    actions,
  } = useMemo(() => {
    let status: keyof typeof STATUS_MAP = 'idle';
    if (isOffline) {
      status = 'connectionError';
    }
    if (cachedError) {
      status = 'error';
    }
    if (isUpdatingOrPersisting) {
      status = 'loading';
    }
    if (hasExpired) {
      status = 'expired';
    }

    return STATUS_MAP[status];
  }, [isOffline, cachedError, isUpdatingOrPersisting, hasExpired]);

  const id = 'status-indicator';

  let displayErrorContext = 'updating';
  if (cachedError) {
    displayErrorContext = ERROR_MAP[cachedError];
  }

  const errorMessageWithContext = `Error while ${displayErrorContext}.  \n${cachedError}`;

  if (!show && !isOffline && !hasExpired) {
    return null;
  }

  return (
    <StatusErrorPopover
      show={showErrorPopover}
      title={errorTitle}
      message={cachedError ? errorMessageWithContext : errorMessage}
      description={errorDescription}
      actions={actions}
      onDismissPopover={() => {
        setShowErrorPopover(false);
      }}
    >
      <Box
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        px={3}
        id={id}
        minWidth={[null, null, 90]}
      >
        <Box
          bg={iconBg}
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          height="1.5rem"
          width="1.5rem"
        >
          {icon}
        </Box>
        <Device.Match greaterThan={1}>
          <Text color="text" fontSize="87.5" ml="space1.5">
            {message}
          </Text>
        </Device.Match>
        {showIndication && (
          <TooltipOld
            placement="bottom"
            referenceId="status-indicator"
            description="Automatically saving your work"
          />
        )}
      </Box>
    </StatusErrorPopover>
  );
};
