import type { Question } from '@mentimeter/http-clients';
import type { Slide as ViewOnlySlide } from '@mentimeter/view-only-schema/api-types-overrides';
import { getCommonSlideProperty } from '../../../forward-compatibility/get-common-slide-property';

export const mapViewOnlySlidePropertyToQuestionProperty = (
  question: Question,
  key: keyof ViewOnlySlide,
) => {
  switch (key) {
    case 'slideId':
      return question.id;

    case 'slideAdminKey':
      return question.id;

    case 'slidePublicKey':
      return question.public_key;

    case 'speakerNotes': {
      return question.speaker_notes ?? '';
    }

    case 'hasComments':
      return question.has_comments;

    default:
      return getCommonSlideProperty(question, key);
  }
};
