import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const participationSettingsToSeriesParticipationAuthenticationPolicy = (
  participationSettings: EditorSlideDeck['participationSettings'] | undefined,
): Series['participation_authentication_policy'] => {
  return (
    participationSettings?.participationAuthenticationPolicy ?? 'unavailable'
  );
};
