import { MentiError } from '@mentimeter/errors/sentry';
import { useContext, useMemo } from 'react';
import {
  SFINX_PRESENTATION_SETTINGS_OVERRIDE_KEYS,
  SfinxPresentationSettingsStateContext,
  type SfinxPresentationSettings,
  type SfinxPresentationSettingsState,
} from './SfinxPresentationSettingsProvider';

export function usePresentationSettings(): Required<SfinxPresentationSettings> {
  const data = useContext(SfinxPresentationSettingsStateContext);
  if (!data) {
    throw new MentiError('SfinxError: no series data found', {
      feature: 'sfinx',
    });
  }

  const settings = useMemo<Required<SfinxPresentationSettings>>(() => {
    const settings: Partial<SfinxPresentationSettings> = {};
    for (const prop of SFINX_PRESENTATION_SETTINGS_OVERRIDE_KEYS) {
      settings[prop] = getOverrideOrFallback(prop, data) as never;
    }
    return settings as Required<SfinxPresentationSettings>;
  }, [data]);

  return settings;
}

function getOverrideOrFallback<
  K extends keyof Required<SfinxPresentationSettings>,
>(key: K, data: SfinxPresentationSettingsState): SfinxPresentationSettings[K] {
  const override = data.overrides[key];
  if (override !== undefined) {
    return override as SfinxPresentationSettings[K];
  }

  const series = data.series as Partial<typeof data.series | undefined>;
  let value;
  switch (key) {
    case 'liveChatMode':
      value = series?.liveChatSettings?.liveChatMode ?? 'disabled';
      break;
    case 'qaModerationPolicy':
      value = series?.qaSettings?.moderationPolicy ?? 'not-moderated';
      break;
    case 'participationIdentityMode':
      value =
        series?.participationSettings?.participationIdentityMode ?? 'anonymous';
      break;
    case 'participationResponseMode':
      value =
        series?.participationSettings?.participationResponseMode ??
        'responses-blocked';
      break;
    case 'qaQuestionVisibility':
      value = series?.qaSettings?.questionVisibility ?? 'moderators-only';
      break;
    case 'qaEnablementScope':
      value = series?.qaSettings?.enablementScope ?? 'qa-slides-only';
      break;
    case 'reactionsAllowed':
      value = series?.reactionSettings?.reactionsAllowed ?? [];
      break;
    case 'participationKey':
      value = series?.participationSettings?.participationKey;
      break;
    case 'seriesId':
      value = series?.slideDeckPublicKey;
      break;
    default:
      throw new MentiError(`SfinxError: unhandled key: ${key}`, {
        feature: 'sfinx',
      });
  }
  return value as SfinxPresentationSettings[K];
}
