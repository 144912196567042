import { Box } from '@mentimeter/ragnar-ui';
import type { BoxT } from '@mentimeter/ragnar-ui';
import React, { useCallback } from 'react';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { px } from '@mentimeter/ragnar-utils';
import { useTabbedInterface } from './hooks';

interface TabProps extends Omit<BoxT, 'onClick'> {
  id: string;
  disabled?: boolean;
  children: React.ReactNode;
  onClick: (dismissable: boolean, active: boolean, id: string) => void;
}

export const Tab = ({
  id,
  disabled,
  children,
  extend: extendProp,
  onClick,
  ...boxProps
}: TabProps) => {
  const {
    id: tabbedInterfaceId,
    dismissable,
    activeTabId,
    focusableTabId,
    registerTab,
  } = useTabbedInterface();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const propsRef = React.useRef({ id, disabled });
  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

  // keep propsRef up to date
  useIsomorphicLayoutEffect(() => {
    propsRef.current.id = id;
  }, [id]);
  useIsomorphicLayoutEffect(() => {
    propsRef.current.disabled = disabled;
  }, [disabled]);

  useIsomorphicLayoutEffect(
    // @ts-expect-error-auto TS(2322) FIXME: Type 'MutableRefObject<{ id: string; disabled: boo... Remove this comment to see the full error message
    () => registerTab({ ref, propsRef }),
    [registerTab],
  );

  const active = id === activeTabId;
  const focusable = focusableTabId === id;

  const extend: Extend = ({ theme }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:focus-visible': {
      zIndex: 1,
    },
    '&:focus-visible:before': {
      content: '""',
      outline: `4px solid ${theme.colors.interactiveFocused}`,
      outlineOffset: '2px',
      borderRadius: px(theme.kosmosBorderRadius['2xl']),
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    ...(extendProp ? extendProp({ theme }) : {}),
  });

  const handleClick = useCallback(() => {
    onClick(dismissable, active, id);
  }, [dismissable, active, id, onClick]);

  return (
    <Box
      {...boxProps}
      id={`${tabbedInterfaceId}-tab-${id}`}
      ref={ref}
      as="button"
      role="tab"
      aria-selected={active}
      aria-controls={`${tabbedInterfaceId}-tabpanel-${id}`}
      // @ts-expect-error-auto TS(2322) FIXME: Type '{ children: ReactNode; id: string; ref: Muta... Remove this comment to see the full error message
      disabled={disabled}
      tabIndex={focusable ? undefined : -1}
      extend={extend}
      onClick={handleClick}
    >
      {children}
    </Box>
  );
};
