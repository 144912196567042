export const COLLABORATOR_STATUS_COLLABORATOR = 'collaborator';
export const COLLABORATOR_STATUS_NOT_FOUND = 'not-found';
export const COLLABORATOR_STATUS_COMMENT = 'comment';
export const COLLABORATOR_STATUS_VIEW = 'view';

/**
 * No permissions to present or edit the presentation.
 */
export const COLLABORATOR_STATUS_NO_PERMISSIONS = 'no-permissions';

export type CollaboratorStatus =
  | typeof COLLABORATOR_STATUS_COLLABORATOR
  | typeof COLLABORATOR_STATUS_NOT_FOUND
  | typeof COLLABORATOR_STATUS_NO_PERMISSIONS
  | typeof COLLABORATOR_STATUS_COMMENT
  | typeof COLLABORATOR_STATUS_VIEW;
