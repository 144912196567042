import type { Question } from '@mentimeter/http-clients';
import type { QuizOpenStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getQuizOpenStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );
  const content: QuizOpenStaticContentSchema = {
    type: 'quiz-open',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: questionToSlideImageReference(question),
    styling: {
      textSizeOffset,
    },
  };

  return content;
};
