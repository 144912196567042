import {
  DropdownMenuContainer,
  type IconButtonProps,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@mentimeter/ragnar-ui';
import {
  trackOpenedRemoteView,
  type InternalPresentTrackingPlacements,
  type MSTeamsTrackingPlacements,
} from '@mentimeter/editor-tracking';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import {
  PlaySolidIcon,
  MSTeamsIcon,
  SmartphoneIcon,
  RefreshIcon,
} from '@mentimeter/ragnar-visuals';
import { useId } from 'react';
import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { PRESENT_ACTION_TITLES } from '../data-handlers/HeaderPresentDataHandler/constants';
import { HeaderCta } from './HeaderCta';

const getActions = (
  persistingTargetEnabled: boolean,
  newPresentationMode: boolean,
  seriesId: string,
  isSmallDevice: boolean,
  hasResults?: boolean,
  isResetResultsExperimentEnabled?: boolean,
  handlePresentInternal?: (
    placement: InternalPresentTrackingPlacements,
  ) => void,
  handleStartNewSession?: (
    placement: InternalPresentTrackingPlacements,
  ) => void,
  handlePresentMsTeams?: (placement: MSTeamsTrackingPlacements) => void,
) => {
  const presentMentimeterAction = persistingTargetEnabled ? (
    <HeaderCta.CtaDropdownItem
      key="internal"
      disabled={newPresentationMode}
      onSelect={() => handlePresentInternal?.('Chevron')}
      icon={PlaySolidIcon}
      information={
        isResetResultsExperimentEnabled && hasResults
          ? 'Continue with the session.'
          : undefined
      }
      id="present-in-mentimeter-button"
    >
      {isResetResultsExperimentEnabled && hasResults
        ? PRESENT_ACTION_TITLES.withResults
        : PRESENT_ACTION_TITLES.internal}
    </HeaderCta.CtaDropdownItem>
  ) : undefined;

  const startNewSessionAction =
    isResetResultsExperimentEnabled && hasResults ? (
      <HeaderCta.CtaDropdownItem
        key="new-session"
        disabled={newPresentationMode}
        onSelect={() => handleStartNewSession?.('Chevron')}
        icon={RefreshIcon}
        information="Save results and start again."
        id="start-new-session-button"
      >
        Start a new session
      </HeaderCta.CtaDropdownItem>
    ) : null;

  const presentInMsTeamsAction = (
    <HeaderCta.CtaDropdownItem
      key="msteams"
      disabled={newPresentationMode}
      onSelect={() => handlePresentMsTeams?.('Chevron')}
      information="Open presentation in a Microsoft Teams meeting."
      icon={MSTeamsIcon}
      id="present-in-teams-button"
    >
      Present in Microsoft Teams
    </HeaderCta.CtaDropdownItem>
  );

  const openMentimoteAction = (
    <HeaderCta.CtaDropdownItem
      key="open-mentimote"
      onSelect={() => {
        trackOpenedRemoteView();
        window.open(
          buildPresentationPath({ seriesId, mode: 'mentimote' }),
          '_blank',
        );
      }}
      icon={SmartphoneIcon}
      information="Mentimote turns your smartphone into a presentation remote"
      id="mentimote-button"
    >
      Open Mentimote
    </HeaderCta.CtaDropdownItem>
  );

  const dropdownMenuSeparator =
    (isResetResultsExperimentEnabled && presentMentimeterAction) ||
    startNewSessionAction ? (
      <DropdownMenuSeparator key="separator" />
    ) : null;

  return isSmallDevice
    ? [openMentimoteAction]
    : [
        presentMentimeterAction,
        startNewSessionAction,
        dropdownMenuSeparator,
        presentInMsTeamsAction,
        openMentimoteAction,
      ].filter(Boolean);
};

export const HeaderPresentButtonDropdown = ({
  size,
  persistingTargetEnabled,
  newPresentationMode,
  seriesId,
  isResultsExperimentEnabled,
  handlePresentInternal,
  handleStartNewSession,
  handlePresentMsTeams,
  disabled,
  hasResults,
}: {
  size?: IconButtonProps['size'];
  persistingTargetEnabled: boolean;
  newPresentationMode: boolean;
  seriesId: string;
  isResultsExperimentEnabled?: boolean;
  handlePresentInternal?: (
    placement: InternalPresentTrackingPlacements,
  ) => void;
  handleStartNewSession?: (
    placement: InternalPresentTrackingPlacements,
  ) => void;
  handlePresentMsTeams?: (placement: MSTeamsTrackingPlacements) => void;
  disabled?: boolean;
  hasResults?: boolean;
}) => {
  const { isSmallDevice } = useDeviceChecker();

  const actionMenuId = useId();
  const actions = !disabled
    ? getActions(
        persistingTargetEnabled,
        newPresentationMode,
        seriesId,
        isSmallDevice,
        hasResults,
        isResultsExperimentEnabled,
        handlePresentInternal,
        handleStartNewSession,
        handlePresentMsTeams,
      )
    : [];

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <HeaderCta.DropdownTrigger
          data-testid="present-dropdown-button"
          aria-label="Open present options"
          variant="brand"
          id={actionMenuId}
          size={size}
          disabled={disabled}
        />
      </DropdownMenuTrigger>
      {!disabled && (
        <DropdownMenuContainer width="336px">{actions}</DropdownMenuContainer>
      )}
    </DropdownMenuRoot>
  );
};
