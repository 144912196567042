import {
  DropdownMenuContainer,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  IconButton,
  Text,
  TooltipOld,
} from '@mentimeter/ragnar-ui';
import {
  CheckIcon,
  CrossIcon,
  LinkIcon,
  MoreVerticalIcon,
  PencilIcon,
} from '@mentimeter/ragnar-visuals';
import { useState } from 'react';
import { DeleteModalDataHandler } from '../data-handlers/DeleteModalDataHandler';
import { getCommentLink } from '../util/getCommentLink';
import { DeleteActionModalTrigger } from './DeleteActionModalTrigger';

const COPY_LINK_TOOLTIP_DESCRIPTION_DEFAULT = 'Click to copy link';
const COPY_LINK_TOOLTIP_DESCRIPTION_COPIED = 'Link copied';
const COPY_LINK_TOOLTIP_DESCRIPTION_FAILED = 'Failed to copy link';

export const ActionMenuDropdown = ({
  commentId,
  resolved,
  onOpenActionMenuChange,
  onEditClicked,
  canEdit,
  canCopyLink,
  canDelete,
}: {
  commentId: string;
  resolved: boolean;
  onOpenActionMenuChange: () => void;
  onEditClicked: () => void;
  canEdit: boolean;
  canCopyLink: boolean;
  canDelete: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [tooltipState, setTooltipState] = useState({
    text: COPY_LINK_TOOLTIP_DESCRIPTION_DEFAULT,
    icon: <LinkIcon />,
  });

  const onCopyLinkClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const commentLink = getCommentLink(commentId, resolved);

    navigator.clipboard
      .writeText(commentLink)
      .then(() => {
        setTooltipState({
          text: COPY_LINK_TOOLTIP_DESCRIPTION_COPIED,
          icon: <CheckIcon />,
        });
        setTimeout(() => {
          setTooltipState({
            text: COPY_LINK_TOOLTIP_DESCRIPTION_DEFAULT,
            icon: <LinkIcon />,
          });
        }, 2000);
      })
      .catch((err) => {
        setTooltipState({
          text: COPY_LINK_TOOLTIP_DESCRIPTION_FAILED,
          icon: <CrossIcon />,
        });
      });
  };

  return (
    <>
      <DropdownMenuRoot onOpenChange={onOpenActionMenuChange}>
        <DropdownMenuTrigger>
          <IconButton
            size="compact"
            borderRadius="full"
            variant="secondary"
            aria-label="manage comment options"
          >
            <MoreVerticalIcon />
          </IconButton>
        </DropdownMenuTrigger>
        <DropdownMenuContainer>
          {canEdit && (
            <DropdownMenuItem
              flexDirection="row"
              alignItems="center"
              onClick={onEditClicked}
            >
              <PencilIcon />
              <Text ml="space2" fontSize="87.5">
                Edit
              </Text>
            </DropdownMenuItem>
          )}
          {canCopyLink && (
            <DropdownMenuItem
              id="copy-link-tooltip"
              flexDirection="row"
              alignItems="center"
              onClick={onCopyLinkClicked}
            >
              {tooltipState.icon}
              <Text ml="space2" fontSize="87.5">
                Copy link
              </Text>
              <TooltipOld
                description={tooltipState.text}
                referenceId="copy-link-tooltip"
                placement="right"
              />
            </DropdownMenuItem>
          )}
          {canDelete && (
            <DeleteActionModalTrigger
              onOpenDeleteModalChange={() => setModalOpen(!modalOpen)}
            />
          )}
        </DropdownMenuContainer>
      </DropdownMenuRoot>
      <DeleteModalDataHandler
        commentId={commentId}
        open={modalOpen}
        onOpenDeleteModalChange={() => setModalOpen(!modalOpen)}
      />
    </>
  );
};
