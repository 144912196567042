/** CX-REFACTOR: Duplicated from applications/editor/src/features/core/editor/state/questions/constants.tsx. That one should be remove in the future */
// ALTERNATIVE AMOUNT HARD CAP
export const DEFAULT_ALTERNATIVE_AMOUNT_SOFT_CAP = 10;
export const CHOICES_ALTERNATIVE_AMOUNT_HARD_CAP = 30;
export const SLIDE_BULLETS_ALTERNATIVE_AMOUNT_SOFT_CAP = 7;
export const SLIDE_BULLETS_ALTERNATIVE_AMOUNT_HARD_CAP = 12;
export const PRIO_ALTERNATIVE_AMOUNT_HARD_CAP = 30;
export const RANKING_ALTERNATIVE_AMOUNT_SOFT_CAP = 8;
export const RANKING_ALTERNATIVE_AMOUNT_HARD_CAP = 12;
export const QUIZ_ALTERNATIVE_AMOUNT_HARD_CAP = 6;
export const QUIZ_ALTERNATIVE_AMOUNT_SOFT_CAP = -1;
export const RATING_ALTERNATIVE_AMOUNT_HARD_CAP = 20;
export const SCALES_ALTERNATIVE_AMOUNT_HARD_CAP = 8;
export const SCALES_ALTERNATIVE_AMOUNT_SOFT_CAP = 6;
export const PIE_ALTERNATIVE_AMOUNT_MAX = 12; // Donut, Pie, and Dots charts are treated same

// QUESTION CHARACTER CAPS
export const DEFAULT_ALTERNATIVE_CHAR_HARD_CAP = 150;
export const QUIZ_DEFAULT_ALTERNATIVE_CHAR_HARD_CAP = 70;

// SLIDE CHARACTER CAPS
export const DEFAULT_SLIDE_TEXT_CHAR_HARD_CAP = 90;

// THEME EDITOR CHARACTER CAPS
export const DEFAULT_THEME_NAME_CHAR_HARD_CAP = 50;

// NUMBER OF QUESTION PER PRESENTATION CAP
export const QUESTION_AMOUNT_HARD_CAP = 100;

// QUESTION/HEADING CHARACTER LIMIT
export const QUESTION_HEADING_CHAR_LIMIT = 150;
