import type { TrackingContext } from '@mentimeter/http-clients';
import { useReturnTo } from '@mentimeter/return-to';
import { trackBackButton } from './tracking';

export const useHeaderBackButton = (
  trackingContext: TrackingContext,
  isAuthenticatedUser: boolean,
) => {
  const { clearReturnToValues, returnHref, returnLabel } =
    useReturnTo('back-from-editor');
  const onGoBack = () => {
    if (isAuthenticatedUser) {
      trackBackButton(trackingContext);
    }
    clearReturnToValues();
  };

  if (!isAuthenticatedUser) {
    return {
      backHref: '/',
      backLabel: '',
      onGoBack,
    };
  }

  const getBackButtonData = () => {
    const baseText = 'Back to';
    const isComingFromPage = Boolean(returnHref) && Boolean(returnLabel);

    const href = isComingFromPage ? returnHref : '/app/home';
    const label = isComingFromPage ? returnLabel : 'Home';

    return {
      backHref: href!,
      backLabel: `${baseText} ${label}`,
    };
  };

  const { backHref, backLabel } = getBackButtonData();

  return { backHref, backLabel, onGoBack };
};
