import type { Question } from '@mentimeter/http-clients';
import type { QuestionsFromAudienceStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getQuestionsFromAudienceStaticContentSchemaStaticContent = (
  question: Question,
) => {
  const content: QuestionsFromAudienceStaticContentSchema = {
    version: 'v1',
    type: 'questions-from-audience',
    styledTitle: question.question_styled ?? {},
    description: question.question_description,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
