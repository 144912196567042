import { VALID_MODULE_IDS } from '@mentimeter/http-clients';
import type { ValidModuleId } from '@mentimeter/http-clients';

export const getValidModuleIdFromString = (moduleId: string): ValidModuleId => {
  const validId = VALID_MODULE_IDS.find((id) => id === moduleId);
  if (validId) {
    return validId;
  }
  return 'deprecated';
};
