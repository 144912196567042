import { getSeriesRequest } from '@core-api/editor/editor/series/{series_id}';
import { createEditorSlideCompatibilityLayer } from '@mentimeter/compatibility/editor';
import { useData } from '@mentimeter/core-hooks';
import type {
  QuestionWithSlide,
  Slide,
} from '@mentimeter/editor-schema/api-types-overrides';
import { useCompatibilityQuestionFetcher } from '@mentimeter/migration-utils';
import { useCallback, useMemo } from 'react';
import { type SWRConfiguration } from 'swr';
import invariant from 'tiny-invariant';
import type { Question } from '@mentimeter/http-clients';
import { questionsCacheKey } from './cache-keys';
import type { GetQuestionsCacheKey } from './types';

interface UseEditorSlidesOptions {
  swrConfig?: SWRConfiguration;
  getQuestionsCacheKey?: GetQuestionsCacheKey | undefined;
  excludeSkipped?: boolean;
}

interface UseEditorSlidesReturn {
  /**
   * **[Deprecated]** Use {@link UseEditorSlidesReturn.slides useEditorSlides().slides} instead.
   * @deprecated
   */
  questions: Array<QuestionWithSlide>;
  slides: Array<QuestionWithSlide>;
  error: any;
  revalidate: () => Promise<{
    responseForBackwardCompat: Array<Question> | Array<Slide> | undefined;
  }>;
  /**
   * **[Deprecated]** Use {@link UseEditorSlidesReturn.lazyGetSlides useEditorSlides().lazyGetSlides} instead.
   * @deprecated
   */
  lazyGetQuestions: () => Array<QuestionWithSlide>;
  lazyGetSlides: () => Array<QuestionWithSlide>;
}

export const useEditorSlides = (
  seriesId: string,
  options: UseEditorSlidesOptions = {},
): UseEditorSlidesReturn => {
  invariant(seriesId, 'useEditorSlides(SWR): seriesId is required');

  const getCacheKey = options?.getQuestionsCacheKey ?? questionsCacheKey;
  const cacheKey = getCacheKey(seriesId);
  const shouldUseNewEndpoints = true;

  const fetcher = useCompatibilityQuestionFetcher<
    Array<Question> | Array<Slide>
  >(seriesId, getSeriesRequest, shouldUseNewEndpoints);

  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    suspense: true,
    focusThrottleInterval: 1000 * 60 * 2,
    revalidateIfStale: false,
    ...options.swrConfig,
  };

  const { data, error, revalidate, lazyData } = useData<
    Array<Question> | Array<Slide>
  >(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    swrConfig,
  );

  const wrappedLazyData = useCallback(() => {
    const slides = lazyData();

    return (
      slides?.map((question) =>
        createEditorSlideCompatibilityLayer(question),
      ) ?? []
    );
  }, [lazyData]);

  const memoized = useMemo(() => {
    const slides =
      data
        ?.map(createEditorSlideCompatibilityLayer)
        ?.filter(
          (question) => !(options.excludeSkipped && question.skip_slide),
        ) ?? [];

    return {
      slides,
      questions: slides,
      error,
      revalidate,
      lazyGetSlides: wrappedLazyData,
      lazyGetQuestions: wrappedLazyData,
    };
  }, [data, error, revalidate, wrappedLazyData, options.excludeSkipped]);

  return memoized;
};
