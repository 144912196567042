import type React from 'react';
import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import { createLazyWithCache } from './lazyWithCache';
import { getValidModuleIdFromString } from './get-valid-module-id';

const MODULE_ICON_IMPORT_MAP = {
  '100-points': () => import('@mentimeter/question-module-100-points/Icon'),
  big: () => import('@mentimeter/question-module-big/Icon'),
  bullets: () => import('@mentimeter/question-module-bullets/Icon'),
  document: () => import('@mentimeter/question-module-document/Icon'),
  choices: () => import('@mentimeter/question-module-choices/Icon'),
  deprecated: () => import('@mentimeter/question-module-deprecated/Icon'),
  'free-text': () => import('@mentimeter/question-module-free-text/Icon'),
  'google-slides': () =>
    import('@mentimeter/question-module-google-slides/Icon'),
  heading: () => import('@mentimeter/question-module-heading/Icon'),
  image: () => import('@mentimeter/question-module-image/Icon'),
  instructions: () => import('@mentimeter/question-module-instructions/Icon'),
  metadata: () => import('@mentimeter/question-module-metadata/Icon'),
  miro: () => import('@mentimeter/question-module-miro/Icon'),
  number: () => import('@mentimeter/question-module-number/Icon'),
  'numerical-question': () =>
    import('@mentimeter/question-module-numerical-question/Icon'),
  open: () => import('@mentimeter/question-module-open/Icon'),
  paragraph: () => import('@mentimeter/question-module-paragraph/Icon'),
  'pin-on-image': () => import('@mentimeter/question-module-pin-on-image/Icon'),
  powerpoint: () => import('@mentimeter/question-module-powerpoint/Icon'),
  qfa: () => import('@mentimeter/question-module-qfa/Icon'),
  'quiz-choices': () => import('@mentimeter/question-module-quiz-choices/Icon'),
  'quiz-leaderboard': () =>
    import('@mentimeter/question-module-quiz-leaderboard/Icon'),
  'quiz-open': () => import('@mentimeter/question-module-quiz-open/Icon'),
  quote: () => import('@mentimeter/question-module-quote/Icon'),
  ranking: () => import('@mentimeter/question-module-ranking/Icon'),
  rating: () => import('@mentimeter/question-module-rating/Icon'),
  scales: () => import('@mentimeter/question-module-scales/Icon'),
  video: () => import('@mentimeter/question-module-video/Icon'),
  wordcloud: () => import('@mentimeter/question-module-wordcloud/Icon'),
} satisfies Record<ValidModuleId, () => Promise<{ Icon: React.ComponentType }>>;

const lazyWithCache = createLazyWithCache<ValidModuleId>();
/**
 * @deprecated for useage outside `question-modules-importer` package. Use Icon component from this package instead
 */
export const importIconDynamically = (
  moduleId: ModuleId,
):
  | React.ComponentType<any>
  | React.LazyExoticComponent<React.ComponentType<any>> => {
  const validModuleId = getValidModuleIdFromString(moduleId);
  // Evaluate only when it gets called
  const importFn = MODULE_ICON_IMPORT_MAP[validModuleId];

  return lazyWithCache({
    cacheKey: validModuleId,
    importFn: async () => {
      const { Icon } = await importFn();
      return Icon;
    },
  });
};
