import {
  Box,
  IconButton,
  TooltipOld,
  type IconButtonProps,
} from '@mentimeter/ragnar-ui';
import { forwardRef } from 'react';

interface HeaderIconButtonProps extends IconButtonProps {
  id: string;
  label?: string | undefined;
  showIndicator?: boolean | undefined;
}

export const HeaderIconButton = forwardRef(
  (
    { id, label, showIndicator, children, ...props }: HeaderIconButtonProps,
    forwardedRef,
  ) => {
    return (
      <>
        <IconButton
          size="compact"
          variant="tertiary"
          borderRadius="full"
          ref={forwardedRef}
          id={id}
          aria-label={label}
          {...props}
        >
          {children}
          {showIndicator && (
            <Box
              position="absolute"
              top="-8px"
              right="-8px"
              width="8px"
              height="8px"
              borderRadius={3}
              bg="info"
            />
          )}
        </IconButton>

        {label && (
          <TooltipOld referenceId={id} description={label} placement="bottom" />
        )}
      </>
    );
  },
);
