import type { Series } from '@mentimeter/http-clients';
import type { CompatibilitySlideDeck } from '../../../compatibility-types';

export function segmentationsToSeriesSegmentations(
  slides: CompatibilitySlideDeck['slides'] | undefined,
  segmentations: CompatibilitySlideDeck['segmentations'] | undefined,
): Series['segmentations'] {
  if (!Array.isArray(slides) || !Array.isArray(segmentations)) {
    return {};
  }

  return segmentations.reduce<Series['segmentations']>(
    (acc, { targetInteractiveContentId, sourceInteractiveContentId }) => {
      const findLegacyQuestionAdminKey = (id: string) => {
        return slides.find((slide) => {
          return slide.interactiveContents[0]?.interactiveContentId === id;
        })?.slideAdminKey;
      };

      const targetLegacyQuestionAdminKey = findLegacyQuestionAdminKey(
        targetInteractiveContentId,
      );

      const sourceLegacyQuestionAdminKey = findLegacyQuestionAdminKey(
        sourceInteractiveContentId,
      );

      if (!targetLegacyQuestionAdminKey || !sourceLegacyQuestionAdminKey) {
        return acc;
      }

      return {
        ...acc,
        [targetLegacyQuestionAdminKey]: sourceLegacyQuestionAdminKey,
      };
    },
    {},
  );
}
