import type { Question } from '@mentimeter/http-clients';
import type { ScalesInteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { StaticContentSchema } from '@mentimeter/schema/static-content';
import type { CompatibilitySlide } from '../../compatibility-types';
import { RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES } from '../../constants';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import { scalesResponseRangeLabelsToQuestionDimensions } from '../converters/interactive-content/slide-scales-response-range-labels-to-question-dimensions';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionScalesProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0];
  const interactiveContentsStyling =
    interactiveContents?.styling as ScalesInteractiveContentStylingSchema;

  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'scales' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'scales_total_average':
        return interactiveContentsStyling.showAverage;
      case 'hide_skip':
        return (
          interactiveContents.responsePolicy ===
          RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES
        );
      case 'dimensions':
        const dimensions = scalesResponseRangeLabelsToQuestionDimensions(
          interactiveContentsStyling.responseRangeLabels,
        );

        const cacheKey = JSON.stringify(dimensions);
        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'dimensions',
          cacheKey,
          dimensions,
        );
      case 'range':
        return interactiveContents.responseRange;
      default:
        return undefined;
    }
  }
};
