import { Text, TooltipOld } from '@mentimeter/ragnar-ui';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { useMatch } from '@mentimeter/ragnar-device';
import { px } from '@mentimeter/ragnar-utils';
import { Tab as TabC } from '../../components/tabs/Tab';

const tabExtend: Extend = ({ theme }) => {
  return {
    color: theme.colors.text,
    background: 'transparent',
    boxShadow: ` inset 0 0 0 ${theme.kosmosSpacing.space1}px ${theme.colors.bg}`,
    '&:enabled': {
      cursor: 'pointer',
      '&[aria-selected=true]': {
        color: theme.colors.onSecondaryWeakest,
        background: theme.colors.secondaryWeakest,
        outline: `1px solid ${theme.colors.secondary}`,
        outlineOffset: '-4px',
      },
      '&:hover[aria-selected=false]': {
        background: theme.colors.neutralWeakest,
        borderRadius: px(theme.kosmosBorderRadius.xl),
      },
      '&:hover[aria-selected=true]': {
        backgroundColor: theme.colors.secondaryWeak,
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: theme.opacity.disabled,
    },
  };
};

interface SidebarTabProps {
  id: string;
  icon: React.ReactNode;
  title: string;
  disabled: boolean;
  disabledDescription: string | undefined;
  onClick: (dismissable: boolean, active: boolean, id: string) => void;
}

export const SidebarTab = ({
  id,
  icon,
  title,
  disabled,
  disabledDescription,
  onClick,
}: SidebarTabProps) => {
  const isMobile = useMatch({ lessThan: 2 });
  const isTablet = useMatch({ equals: 2 });

  return (
    <TabC
      id={id}
      disabled={disabled}
      alignItems="center"
      justifyContent="center"
      p="space2"
      minWidth={['88px', '56px', '96px']}
      height={['undefined', '56px', '84px']}
      borderRadius="2xl"
      onClick={onClick}
      extend={tabExtend}
    >
      {icon}
      {!isTablet && (
        <Text
          mt="space1.5"
          fontSize={isMobile ? '75' : '87.5'}
          color="inherit"
          fontWeight="semiBold"
        >
          {title}
        </Text>
      )}
      {disabledDescription && (
        <TooltipOld
          referenceId={`sidebar-tab-${id}`}
          description={disabledDescription}
          placement={isMobile ? 'top' : 'left'}
        />
      )}
    </TabC>
  );
};
