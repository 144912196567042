import React from 'react';
import { ENGAGEMENT_COPY } from '@mentimeter/paywalls-data-hooks';
import { Badge, Box, Text, TooltipOld } from '@mentimeter/ragnar-ui';
import { animated, type SpringValues } from 'react-spring';
import { useProgressBarAnimation } from '../../use-progress-bar-animation';
import { getResetsAtText } from '../../../lib/hooks/get-resets-at-text';

export const EngagementLimitProgressComponent = ({
  progress,
  limit,
  resetsAt,
  showUpgradeBadge,
  showTooltip = true,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  showUpgradeBadge: boolean;
  showTooltip?: boolean;
}) => {
  const { style, progressByLimit } = useProgressBarAnimation(progress, limit);

  return (
    <Box width="100%" alignItems="stretch" mt={3}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          id="engagement-progress-heading"
          extend={() => ({ ...(showTooltip && { cursor: 'help' }) })}
        >
          {ENGAGEMENT_COPY.engagement_type}
        </Text>
        {showTooltip && (
          <TooltipOld
            referenceId="engagement-progress-heading"
            placement="bottom"
            forceOneLine={false}
            width="330px"
            description={ENGAGEMENT_COPY.progress_bar.tooltip}
          />
        )}
        <Box flexDirection="row" alignItems="baseline" ml={3}>
          <Text fontWeight="bold">{progress}</Text>
          <Text>/</Text>
          <Text fontSize={1}>{limit}</Text>
          {showUpgradeBadge && <Badge ml={1} compact type="upgrade" />}
        </Box>
      </Box>
      <Box height="8px" position="relative" my={2}>
        <Box
          extend={({ theme }) => ({
            background: theme.colors.surfaceSunken,
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: `${theme.radii[1]}px`,
          })}
        />
        <Box
          data-testid="engagement-progress"
          data-progress={progressByLimit}
          extend={({ theme }) => ({
            background: theme.colors.secondary,
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: `${theme.radii[1]}px`,
          })}
          as={animated.div}
          style={style as SpringValues}
        />
      </Box>
      <Box>
        <Text lineHeight="none" fontSize={1} color="textWeaker">
          Resets {getResetsAtText(resetsAt)}
        </Text>
      </Box>
    </Box>
  );
};
