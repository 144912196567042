globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"9f4533e004280031087420cc3f52ad38b62fcce9"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/app/presentation";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';
import type { SamplingContext } from '@sentry/types';

const environmentName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev';
const shouldHavePerformanceMonitor = environmentName === 'prod';

Sentry.init({
  dsn: 'https://be81cd6c6415b88eb4eefa3b0c10293f@o866780.ingest.us.sentry.io/4507311200403456',
  ...standardSentryConfig,
  ...extraClientConfig,
  tracesSampler: (samplingContext: SamplingContext) => {
    if (!shouldHavePerformanceMonitor) {
      return 0;
    }

    return samplingContext.location?.pathname.endsWith('/edit') ? 0.05 : 0;
  },
});
