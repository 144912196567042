import type { EditorErrors } from '@mentimeter/core-global-state';
import {
  EditorFlags,
  useEditorError,
  useEditorFlag,
  useIsPersisting,
  useLastRequestTimestamp,
  useUserPresentationRole,
} from '@mentimeter/core-global-state';
import {
  HeaderBack,
  HeaderBadge,
  HeaderLayout,
  HeaderSeparator,
  HeaderSettingsButton,
  HeaderTabs,
} from '@mentimeter/editor-header';
import { type TrackingContext } from '@mentimeter/http-clients';
import { Device, useDevice } from '@mentimeter/ragnar-device';
import { Box, TextEllipsis, TooltipOld } from '@mentimeter/ragnar-ui';
import { useIsOffline } from '@mentimeter/react-hooks';
import type { UserT } from '@mentimeter/user';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { useHeaderBackButton } from '../useHeaderBackButton';
import { CopyToAccount } from './CopyToAccount';
import { HeaderRightUser } from './HeaderRightUser';
import { HeaderRightVisitor } from './HeaderRightVisitor';

interface Props {
  trackingContext: TrackingContext;
  userIsCollaborator: boolean;
  user: UserT | null;
  seriesId: string;
  seriesName: string;
  seriesWorkspaceId: number | null;
  badgeLabel: string;
}

export const HeaderViewOnly = ({
  trackingContext,
  userIsCollaborator,
  user,
  seriesId,
  seriesName,
  seriesWorkspaceId,
  badgeLabel,
}: Props) => {
  const userRole = useUserPresentationRole();
  const isOffline = useIsOffline();
  const isPersisting = useIsPersisting();
  const lastRequestMs = useLastRequestTimestamp();

  const [editorError, setEditorError] = useEditorError<EditorErrors>();
  const [isUpdating] = useEditorFlag(EditorFlags.IsUpdating);

  const isAuthenticatedUser = Boolean(user);
  const { backHref, backLabel, onGoBack } = useHeaderBackButton(
    trackingContext,
    isAuthenticatedUser,
  );

  const { breakpoint } = useDevice();

  const buttonSize = breakpoint > 3 ? 'default' : 'compact';

  return (
    <HeaderLayout>
      <HeaderLayout.Left>
        <HeaderBack
          backLinkHref={backHref}
          tooltipText={backLabel}
          onBackLinkClick={onGoBack}
          size={buttonSize}
          showBackButton={isAuthenticatedUser}
        >
          <Box
            maxWidth={breakpoint > 3 ? 320 : 200}
            flexDirection="row"
            alignItems="center"
            gap="space1"
          >
            <TextEllipsis
              id={`menti-name-${seriesId}`}
              fontSize="87.5"
              fontWeight="semiBold"
              text={seriesName}
              tooltipDescription={seriesName}
            />
            {isAuthenticatedUser && (
              <CopyToAccount
                trackingContext={trackingContext}
                buttonSize={buttonSize}
                seriesId={seriesId}
              />
            )}
          </Box>
          <Device.Match greaterThan={1}>
            <HeaderBadge
              text={`${badgeLabel} only`}
              bg="brandWeakest"
              ml="space2"
              textTransform="none"
            />
            {isAuthenticatedUser && (
              <>
                <HeaderSeparator />
                <HeaderSettingsButton
                  ml="space1"
                  size={buttonSize}
                  label={VIEW_ONLY_DISABLED_MESSAGE}
                  disabled
                />
              </>
            )}
          </Device.Match>

          <HeaderBack.Status
            isOffline={isOffline}
            isUpdatingOrPersisting={isUpdating || isPersisting}
            resetError={() => setEditorError(undefined)}
            error={editorError}
            lastRequestMs={lastRequestMs}
          />
        </HeaderBack>
      </HeaderLayout.Left>

      <Device.Match greaterThan={2}>
        <HeaderLayout.Middle>
          <HeaderTabs
            seriesId={seriesId}
            isWorkspacePresentation={Boolean(seriesWorkspaceId)}
            userRole={userRole}
            trackingContext={trackingContext}
            workspaceRole={user?.role}
            viewOnly
          />
          <TooltipOld
            referenceId="results"
            description={VIEW_ONLY_DISABLED_MESSAGE}
            placement="bottom"
          />
        </HeaderLayout.Middle>
      </Device.Match>

      <HeaderLayout.Right>
        {isAuthenticatedUser && user ? (
          <HeaderRightUser
            buttonSize={buttonSize}
            seriesId={seriesId}
            trackingContext={trackingContext}
            userIsCollaborator={userIsCollaborator}
            user={user}
          />
        ) : (
          <HeaderRightVisitor buttonSize={buttonSize} seriesId={seriesId} />
        )}
      </HeaderLayout.Right>
    </HeaderLayout>
  );
};
