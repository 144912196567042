import {
  HeaderCta,
  HeaderPresentButton,
  HeaderPresentButtonDropdown,
  HeaderPreviewButton,
  HeaderSeparator,
  HeaderShareButton,
  useLastPresentTarget,
  HeaderInviteTeamMemberButton,
} from '@mentimeter/editor-header';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { type TrackingContext } from '@mentimeter/http-clients';
import { EngagementLimitPresentPaywallDataHandler } from '@mentimeter/paywalls/engagement-limit';
import { Device } from '@mentimeter/ragnar-device';
import { Box, TooltipOld } from '@mentimeter/ragnar-ui';
import type { UserT } from '@mentimeter/user';
import { CollaborationUpgradeButton } from '@mentimeter/paywalls/buttons';
import { Suspense, useId } from 'react';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { useSplits } from '@mentimeter/splitio';

export const HeaderRightUser = ({
  buttonSize,
  seriesId,
  user,
  userIsCollaborator,
  trackingContext,
}: {
  buttonSize: 'default' | 'compact';
  seriesId: string;
  trackingContext: TrackingContext;
  userIsCollaborator: boolean;
  user: UserT;
}) => {
  const { INT_Persistent_Present_Button } = useSplits([
    'INT_Persistent_Present_Button',
  ]);
  const persistingTargetEnabled = INT_Persistent_Present_Button === 'on';
  const [lastTarget] = useLastPresentTarget();
  const presentButtonId = useId();
  return (
    <>
      <Device.Match greaterThan={3}>
        <HeaderInviteTeamMemberButton
          onClick={() => {}}
          size={buttonSize}
          disabled={true}
        />
        <HeaderSeparator />
      </Device.Match>

      <Box flexDirection="row" alignItems="center" gap={2}>
        <Device.Match greaterThan={3}>
          <HeaderPreviewButton
            onClick={() => {}}
            size={buttonSize}
            label={VIEW_ONLY_DISABLED_MESSAGE}
            disabled
          />
          <HeaderShareButton
            id="header-share-button"
            size={buttonSize}
            disabled
          />
          <TooltipOld
            referenceId="header-share-button"
            description={VIEW_ONLY_DISABLED_MESSAGE}
            placement="bottom"
          />
        </Device.Match>

        <EngagementLimitPresentPaywallDataHandler seriesId={seriesId}>
          <ErrorBoundary
            feature="creation-experience"
            errorMessage="Could not load Present button in Header"
          >
            <Suspense fallback={null}>
              <Device.Match greaterThan={1}>
                <HeaderCta>
                  <HeaderPresentButton
                    buttonReferenceId={presentButtonId}
                    persistingTargetEnabled={persistingTargetEnabled}
                    lastTarget={lastTarget}
                    size={buttonSize}
                    disabled
                  />
                  <HeaderPresentButtonDropdown
                    size={buttonSize}
                    persistingTargetEnabled={persistingTargetEnabled}
                    newPresentationMode={false}
                    seriesId={seriesId}
                    disabled
                  />
                  <TooltipOld
                    referenceId={presentButtonId}
                    description={VIEW_ONLY_DISABLED_MESSAGE}
                    placement="bottom"
                  />
                </HeaderCta>
              </Device.Match>
            </Suspense>
            {userIsCollaborator && (
              <CollaborationUpgradeButton
                size={buttonSize}
                trackingContext={trackingContext}
                trackingPlacement="Header actions"
                user={user}
              />
            )}
          </ErrorBoundary>
        </EngagementLimitPresentPaywallDataHandler>
      </Box>
    </>
  );
};
