import type { CompatibilitySeriesWithSlideDeck } from '@mentimeter/compatibility/compatibility-types';
import { createContext, useMemo, type PropsWithChildren } from 'react';

export interface SfinxPresentationSettings {
  seriesId?: CompatibilitySeriesWithSlideDeck['slideDeckPublicKey'];
  qaEnablementScope?: CompatibilitySeriesWithSlideDeck['qaSettings']['enablementScope'];
  liveChatMode?: CompatibilitySeriesWithSlideDeck['liveChatSettings']['liveChatMode'];
  qaModerationPolicy?: CompatibilitySeriesWithSlideDeck['qaSettings']['moderationPolicy'];
  participationIdentityMode?: CompatibilitySeriesWithSlideDeck['participationSettings']['participationIdentityMode'];
  participationKey?: CompatibilitySeriesWithSlideDeck['participationSettings']['participationKey'];
  participationResponseMode?: CompatibilitySeriesWithSlideDeck['participationSettings']['participationResponseMode'];
  qaQuestionVisibility?: CompatibilitySeriesWithSlideDeck['qaSettings']['questionVisibility'];
  reactionsAllowed?: CompatibilitySeriesWithSlideDeck['reactionSettings']['reactionsAllowed'];
}

export interface SfinxPresentationSettingsProviderProps
  extends Omit<SfinxPresentationSettings, 'seriesId'> {
  series: CompatibilitySeriesWithSlideDeck;
}

export interface SfinxPresentationSettingsState {
  series: CompatibilitySeriesWithSlideDeck;
  overrides: SfinxPresentationSettings;
}

export const SfinxPresentationSettingsStateContext = createContext<
  SfinxPresentationSettingsState | undefined
>(undefined);

export const SFINX_PRESENTATION_SETTINGS_OVERRIDE_KEYS: Array<
  keyof SfinxPresentationSettings
> = [
  'seriesId',
  'qaEnablementScope',
  'liveChatMode',
  'qaModerationPolicy',
  'participationIdentityMode',
  'participationKey',
  'participationResponseMode',
  'qaQuestionVisibility',
  'reactionsAllowed',
];

export const SfinxPresentationSettingsProvider = ({
  qaEnablementScope: enablementScope,
  liveChatMode,
  qaModerationPolicy: moderationPolicy,
  participationIdentityMode,
  participationKey,
  participationResponseMode,
  qaQuestionVisibility: questionVisibility,
  reactionsAllowed,
  series,
  children,
}: PropsWithChildren<SfinxPresentationSettingsProviderProps>) => {
  const providerValue = useMemo(() => {
    return {
      series,
      overrides: getOverrides({
        seriesId: series?.slideDeckPublicKey,
        qaEnablementScope: enablementScope,
        liveChatMode,
        qaModerationPolicy: moderationPolicy,
        participationIdentityMode,
        participationKey,
        participationResponseMode,
        qaQuestionVisibility: questionVisibility,
        reactionsAllowed,
      } as SfinxPresentationSettings),
    };
  }, [
    enablementScope,
    liveChatMode,
    moderationPolicy,
    participationIdentityMode,
    participationKey,
    participationResponseMode,
    questionVisibility,
    reactionsAllowed,
    series,
  ]);

  return (
    <SfinxPresentationSettingsStateContext.Provider value={providerValue}>
      {children}
    </SfinxPresentationSettingsStateContext.Provider>
  );
};

const maybeAddOverride = <K extends keyof SfinxPresentationSettings>(
  key: K,
  value: SfinxPresentationSettings[K] | undefined,
  overrides: SfinxPresentationSettings,
) => {
  return value === undefined ? overrides : { ...overrides, [key]: value };
};

const getOverrides = (overrides: SfinxPresentationSettings) => {
  return SFINX_PRESENTATION_SETTINGS_OVERRIDE_KEYS.reduce(
    (acc, key) => maybeAddOverride(key, overrides[key], acc),
    {} as SfinxPresentationSettings,
  );
};
