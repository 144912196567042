/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import {
  ConfigProvider as SfinxConfigProvider,
  type SfinxPresentationMode,
} from '@mentimeter/sfinx';
import { getVotingUrl } from 'lib/getVotingUrl';

interface PublicSfinxConfigData {
  mode?: SfinxPresentationMode;
  enableAnimations?: boolean;
  enableHotkeys?: boolean;
  renderSlideControls?: boolean;
}

const votingUrl = getVotingUrl();
const VOTING_URL = process.env.NEXT_PUBLIC_VOTING_URL as string;
const votingUrlWithoutProtocol = VOTING_URL.replace(/(^\w+:|^)\/\//, '');

export const ConfigProvider = ({
  children,
  mode = 'public',
  enableAnimations = true,
  enableHotkeys = true,
  renderSlideControls = false,
}: React.PropsWithChildren<PublicSfinxConfigData>) => {
  return (
    <SfinxConfigProvider
      value={{
        votingUrl,
        baseUrl: votingUrlWithoutProtocol,
        mode,
        renderSlideControls,
        enableHotkeys,
        enableAnimations,
      }}
    >
      {children}
    </SfinxConfigProvider>
  );
};
