'use client';

import { useParams, usePathname } from '@mentimeter/next-navigation';
import { useReportWebVitals } from 'next/web-vitals';
import { sendDistribution } from '@mentimeter/errors/sendMetric';

const PERFORMANCE_METRICS = ['FCP', 'LCP', 'CLS', 'TTFB'];

export function WebVitals() {
  const currentPath = usePathname();
  const params = useParams();

  useReportWebVitals((metric) => {
    const envName = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
    const path = replaceDynamicParams(currentPath, params);

    const isDev = envName === 'dev';
    // 100% stage, 10% prod
    const isSample = envName !== 'prod' || Math.random() < 0.1;
    const isMetricName = PERFORMANCE_METRICS.includes(metric.name);
    const isMetricType = metric.navigationType === 'navigate';

    if (!isDev && isSample && isMetricName && isMetricType) {
      const value = Math.trunc(metric.value);
      const metricName: `mmjs.${string}` = `mmjs.${metric.name.toLowerCase()}`;
      sendDistribution({
        name: metricName,
        value,
        tags: [
          `route:${path}`,
          `env:${process.env.NEXT_PUBLIC_ENVIRONMENT_NAME}`,
          `service:${process.env.NEXT_PUBLIC_SERVICE_NAME}`,
        ],
      });
    }
  });

  return null;
}

/**
 * Replaces dynamic parameters in the path with the parameter name.
 * eg. /checkout/1234 -> /checkout/[id]
 */
function replaceDynamicParams(
  currentPath: string,
  params: Record<string, string | string[]>,
) {
  let nextJsPath: string = currentPath;

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value) && nextJsPath.includes(value.join('/'))) {
      nextJsPath = nextJsPath.replace(value.join('/'), `[${key}]`);
    }
    if (typeof value === 'string' && nextJsPath.includes(value)) {
      nextJsPath = nextJsPath.replace(value, `[${key}]`);
    }
  }

  return nextJsPath;
}
