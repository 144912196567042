import AuthModalClicker from 'components/AuthModalClicker';
import { Button } from '@mentimeter/ragnar-ui';
import { useSearchParams } from 'next/navigation';
import { useReferral } from '@mentimeter/auth';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';

export const HeaderRightVisitor = ({
  buttonSize,
  seriesId,
}: { buttonSize: 'default' | 'compact'; seriesId: string }) => {
  const referral = useReferral();
  const searchParams = useSearchParams();

  const continueUrl = buildPresentationPath({
    seriesId,
    mode: 'view',
    currentSearchParams: searchParams,
  });

  return (
    <>
      <AuthModalClicker
        showSignup={false}
        continueUrl={continueUrl}
        referral={referral}
        render={({ onClick }) => (
          <Button
            variant="secondary"
            size={buttonSize}
            onClick={onClick}
            mx="space2"
          >
            Log in
          </Button>
        )}
      />
      <AuthModalClicker
        showSignup
        continueUrl={continueUrl}
        referral="try-it-for-free-button"
        render={({ onClick }) => (
          <Button variant="primary" size={buttonSize} onClick={onClick}>
            Sign up
          </Button>
        )}
      />
    </>
  );
};
