import {
  TrackingContext,
  type UserPresentationRole,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';

export const trackShowResults = ({
  role,
  workspaceRole,
  isSharedPresentation,
  seriesId,
  trackingContext,
}: {
  role: UserPresentationRole;
  workspaceRole: WorkspaceRole | undefined;
  isSharedPresentation: boolean;
  seriesId: string;
  trackingContext: TrackingContext;
}) => {
  trackUser({
    event: 'Opened results view',
    properties: {
      placement: 'Header',
      context: trackingContext,
      role,
      'workspace role': workspaceRole ?? '',
      'shared presentation': isSharedPresentation,
      'series id': seriesId,
    },
  });
};

export const trackOpenEditView = (role: UserPresentationRole) => {
  void trackUser({
    event: 'Opened edit view',
    properties: {
      context: TrackingContext.Results,
      placement: 'Top bar',
      role,
    },
  });
};
