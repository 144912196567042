'use client';

import { createViewOnlySeriesCompatibilityLayer } from '@mentimeter/compatibility/view-only';
import { questionsCacheKey, seriesCacheKey } from '@mentimeter/core-hooks';
// eslint-disable-next-line menti-react/forbidden-legacy-series-import
import type { Series, TrackingContext } from '@mentimeter/http-clients';
import { useSearchParams } from '@mentimeter/next-navigation';
import { useOnce } from '@mentimeter/react-hooks';
import { usersCacheKey, type UserT } from '@mentimeter/user';
import type { SlideDeck } from '@mentimeter/view-only-schema/api-types-overrides';
import { useClearInitialQueryParams } from 'features/core/edit_view/application/clear-query-params';
import { SWRConfig } from 'swr';
import { type CollaboratorStatus } from 'utils/constants';
import { trackViewOnlyEditor } from '../../trackViewOnlyEditor';
import { ViewOnlyPage } from './ViewOnlyPage';

export interface ViewPageProps {
  user: UserT | null;
  seriesResponse:
    | { series: Series; slideDeck: SlideDeck | undefined }
    | { series: Series | undefined; slideDeck: SlideDeck };
  questionId: string;
  collaboratorStatus: CollaboratorStatus;
  trackingContext: TrackingContext;
}

export const ViewOnlyEditor = ({
  user,
  seriesResponse,
  questionId,
  collaboratorStatus,
  trackingContext,
}: ViewPageProps) => {
  const searchParams = useSearchParams();
  const refererSource = searchParams.get('source');

  const slideDeckOrSeries = seriesResponse.slideDeck ?? seriesResponse.series;
  const series = createViewOnlySeriesCompatibilityLayer(slideDeckOrSeries);

  useOnce(true, () =>
    trackViewOnlyEditor(
      user,
      trackingContext,
      {
        ...series!,
        slideCount: series!.questions.length,
      },
      collaboratorStatus,
      refererSource,
    ),
  );
  useClearInitialQueryParams();

  if (!series) return null;

  const seriesId = series.id;
  const unskippedQuestions = series.questions.filter(
    (question) => !question.skip_slide,
  );

  return (
    <SWRConfig
      value={{
        fallback: {
          [seriesCacheKey(seriesId)]: slideDeckOrSeries,
          [questionsCacheKey(seriesId)]: seriesResponse.slideDeck
            ? seriesResponse.slideDeck.slides
            : seriesResponse.series?.questions,
          [usersCacheKey]: user,
        },
      }}
    >
      <ViewOnlyPage
        user={user}
        series={series}
        questions={unskippedQuestions}
        collaboratorStatus={collaboratorStatus}
        questionId={questionId}
        trackingContext={trackingContext}
      />
    </SWRConfig>
  );
};
