import {
  Avatar,
  Box,
  Text,
  TooltipOld,
  getAvatarColor,
} from '@mentimeter/ragnar-ui';
import { useState } from 'react';
import type { Comment } from '@core-api/comments/types/response';
import { format, formatDistance, isAfter, parseISO, subDays } from 'date-fns';
import { getNameInitials } from '@mentimeter/user';
import { ReplyDataHandler } from '../data-handlers/ReplyDataHandler';
import { ResolveCommentDataHandler } from '../data-handlers/ResolveCommentDataHandler';
import { CommentsControlDataHandler } from '../data-handlers/CommentsControlDataHandler';
import { ActionMenuDropdown } from './ActionMenuDropdown';
import { CommentText } from './CommentText';

export const formatRelativeDate = (dateString?: string | null): string => {
  if (!dateString) {
    return '';
  }

  const time = parseISO(dateString);
  return isAfter(time, subDays(Date.now(), 2))
    ? formatDistance(time, Date.now(), { addSuffix: true })
    : format(time, 'LLL d, yyyy');
};

export interface MentionDetails {
  id: string;
  label: string;
  position: DOMRect | null;
  show: boolean;
}

export const CommentDetails = ({
  comment,
  isOwner,
  displayActionMenu,
  displayReplyButton,
  showReplyInput,
  onShowReplyChange,
  onOpenCommentActionsChange,
}: {
  comment: Comment;
  isOwner: boolean;
  displayActionMenu: boolean;
  displayReplyButton: boolean;
  showReplyInput: boolean;
  onShowReplyChange: (arg: boolean) => void;
  onOpenCommentActionsChange: () => void;
}) => {
  const [isEditingExisting, setIsEditingExisting] = useState(false);
  const handleEditClicked = () => {
    setIsEditingExisting(true);
  };
  const isTopLevelComment = comment.parentId === null;

  const canEdit = isOwner && !comment.resolvedAt;
  const canCopyLink = window.isSecureContext;
  const canDelete = isOwner;
  const atLeastOneActionPossible = canCopyLink || canEdit || canDelete;
  const [showEdited, setShowEdited] = useState(false);

  return (
    <>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          flex="1 1 auto"
          flexDirection="row"
          alignItems="center"
          py="space2"
        >
          <Avatar
            aria-hidden
            size="compact"
            itemId={comment.id}
            type="user"
            initials={getNameInitials(comment.authorDisplayName || '', true)}
            name={comment.authorDisplayName}
            profilePictureUrl={comment.authorProfilePictureUrl}
            className={getAvatarColor(comment.authorId.toString())}
          />
          <Text truncate ml="space2" fontSize="87.5">
            {comment.authorDisplayName}
          </Text>
        </Box>
        <Box flexDirection="row">
          {displayActionMenu && atLeastOneActionPossible && (
            <ActionMenuDropdown
              commentId={comment.id}
              resolved={!!comment.resolvedAt}
              onOpenActionMenuChange={onOpenCommentActionsChange}
              onEditClicked={handleEditClicked}
              canCopyLink={canCopyLink}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          )}

          {isTopLevelComment && <ResolveCommentDataHandler comment={comment} />}
        </Box>
      </Box>

      {isEditingExisting ? (
        <CommentsControlDataHandler
          cta="Save"
          showControlButtonsInitially={true}
          isEditingExisting={true}
          value={comment.styledText}
          commentId={comment.id}
          placeholder=""
          parentCommentId={null}
          onSubmitComment={() => setIsEditingExisting(false)}
          onCancel={() => setIsEditingExisting(false)}
          isOwner={isOwner}
        />
      ) : (
        <Text my="space2" mb="space1" fontSize="87.5">
          <CommentText comment={comment} setShowEdited={setShowEdited} />
          {comment.editedAt && showEdited && (
            <>
              <TooltipOld
                placement="bottom"
                referenceId={`edited-${comment.id}`}
                description={`Last edited ${formatRelativeDate(comment.editedAt ?? '')}`}
              />
              <Text
                id={`edited-${comment.id}`}
                color="textWeaker"
                fontSize="75"
              >
                &nbsp;(edited)
              </Text>
            </>
          )}
        </Text>
      )}
      <Box
        flexDirection={showReplyInput ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="stretch"
      >
        {!isEditingExisting && (
          <Text fontSize="75" color="textWeaker" py="space1">
            {formatRelativeDate(comment.createdAt)}
          </Text>
        )}
        {!comment.resolvedAt && !isEditingExisting && (
          <ReplyDataHandler
            comment={comment}
            showReplyInput={showReplyInput}
            onShowReplyChange={onShowReplyChange}
            displayReplyButton={displayReplyButton}
            isOwner={isOwner}
          />
        )}
      </Box>
    </>
  );
};
