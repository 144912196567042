import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const participationSettingsToSeriesParticipationAuthenticationMode = (
  participationSettings: EditorSlideDeck['participationSettings'] | undefined,
): Series['participation_authentication_mode'] => {
  return (
    participationSettings?.participationAuthenticationMode ?? 'unauthenticated'
  );
};
