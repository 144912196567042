import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import { propertyToSeriesProperty as getMigratedSeriesProperty } from '../../../backward-compatibility/converters/slide-deck';
import { createEditorSlideCompatibilityLayer } from '../slide-compatibility-layer/create-editor-slide-compatibility-layer';
import { participationModeSettingsToSeriesClosedForVoting } from '../../../backward-compatibility/converters/slide-deck/participation-mode-to-series-closed-for-voting';
import { participationSettingsToSeriesParticipationIdentityModePolicy } from '../../../backward-compatibility/converters/slide-deck/participation-settings-to-series-participation-identity-mode-policy';
import { participationSettingsToSeriesParticipationAuthenticationMode } from '../../../backward-compatibility/converters/slide-deck/participation-settings-to-series-participation-authentication-mode';
import { participationSettingsToSeriesParticipationAuthenticationPolicy } from '../../../backward-compatibility/converters/slide-deck/participation-settings-to-series-participation-authentication-policy';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import { COLLABORATION_MODE_COLLABORATIVE } from '../../../constants';

export const getMigratedEditorSeriesProperty = (
  target: EditorSlideDeck,
  key: keyof Series | 'slides',
) => {
  switch (key) {
    case 'owner_id':
      return target.ownershipSettings?.ownerId;

    case 'folder_id':
      return target.ownershipSettings?.folderId;

    case 'folder_name':
      return target.ownershipSettings?.folderName;

    case 'results_sharing':
      return target.ownershipSettings?.resultsSharing;

    case 'co_edited':
      return (
        target.ownershipSettings?.collaborationMode ===
        COLLABORATION_MODE_COLLABORATIVE
      );

    case 'expired_responses_deleted_at':
      return target.participationSettings
        ?.participationExpiredResponsesDeletedAt;

    case 'closed_for_voting':
      return participationModeSettingsToSeriesClosedForVoting(
        target.participationSettings,
      );

    case 'participation_identity_mode_policy':
      return participationSettingsToSeriesParticipationIdentityModePolicy(
        target.participationSettings,
      );

    case 'participation_authentication_mode':
      return participationSettingsToSeriesParticipationAuthenticationMode(
        target.participationSettings,
      );

    case 'participation_authentication_policy':
      return participationSettingsToSeriesParticipationAuthenticationPolicy(
        target.participationSettings,
      );

    case 'slides':
      const slides = target.slides.map((slide) => {
        return createEditorSlideCompatibilityLayer(slide);
      });
      const cacheKey = JSON.stringify(slides);
      return getOrSetCache(
        target as WithCache<EditorSlideDeck>,
        'slides',
        cacheKey,
        slides,
      );

    case 'questions': {
      const questions = target.slides.map((slide) => {
        return createEditorSlideCompatibilityLayer(slide);
      });

      const cacheKey = JSON.stringify(questions);
      return getOrSetCache(
        target as WithCache<EditorSlideDeck>,
        'questions',
        cacheKey,
        questions,
      );
    }

    default:
      return getMigratedSeriesProperty(target, key);
  }
};
