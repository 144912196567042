import type {
  Layout,
  ModuleId,
  Question,
  QuestionType,
} from '@mentimeter/http-clients';

export const DEFAULT_LAYOUT = {
  type: 'default',
  font_size_offset: 0,
  alignment_x: 1,
  alignment_y: 1,
  size: '33%',
} as const;

// This is a duplicate of the function in @mentimeter/sfinx (packages/sfinx/src/utils/category.ts)
const getCategory = (type: QuestionType | undefined): string => {
  switch (type) {
    case 'slide':
      return 'content';
    case 'quiz':
    case 'quiz_open':
    case 'quiz_leaderboard':
      return 'quiz';
    case 'qfa':
      return 'qfa';
    default:
      return 'interactive';
  }
};

const getDefaultLayout = (moduleId: ModuleId, type: QuestionType): Layout => {
  const category = getCategory(type);
  // exceptions
  if (
    moduleId === 'wordcloud' ||
    moduleId === 'open' ||
    moduleId === 'qfa' ||
    moduleId === 'video'
  ) {
    return { ...DEFAULT_LAYOUT, type: 'right' };
  } else if (moduleId === 'metadata') {
    return { ...DEFAULT_LAYOUT, type: 'right' };
    // category defaults
  } else if (moduleId === 'pin-on-image') {
    return DEFAULT_LAYOUT;
  } else if (category === 'interactive') {
    return { ...DEFAULT_LAYOUT, type: 'right' };
  } else if (category === 'content') {
    return { ...DEFAULT_LAYOUT, type: 'right' };
  } else {
    return DEFAULT_LAYOUT;
  }
};

type GetQuestionLayoutT = Pick<
  Question,
  'question_image_url' | 'layout' | 'module_id' | 'type'
>;

const getIsLayoutSectionVisible = (moduleId: ModuleId): boolean => {
  return layoutVisibilityByContent[moduleId] || false;
};

export const getQuestionLayout = (
  question: GetQuestionLayoutT,
  mergeQuestionLayout = true,
): Layout | undefined => {
  const hasImage = Boolean(question.question_image_url);

  if (!getIsLayoutSectionVisible(question.module_id)) {
    return undefined;
  }

  const themeDefault = hasImage
    ? getDefaultLayout(question.module_id, question.type)
    : DEFAULT_LAYOUT;

  // The open ended new design should only support image layouts with size 33%
  if (question.module_id === 'open') {
    return {
      ...themeDefault,
      ...{ ...question.layout, size: '33%' },
    };
  }

  const layout =
    mergeQuestionLayout && question.layout
      ? {
          ...themeDefault,
          ...question.layout,
        }
      : themeDefault;

  return layout;
};

const layoutVisibilityByContent: Partial<Record<ModuleId, boolean>> = {
  '100-points': true,
  big: true,
  bullets: true,
  choices: true,
  document: false,
  'free-text': true,
  'google-slides': false,
  heading: true,
  image: false,
  instructions: false,
  metadata: true,
  miro: true,
  number: true,
  'numerical-question': true,
  open: true,
  paragraph: true,
  'pin-on-image': true,
  powerpoint: false,
  qfa: true,
  'quiz-choices': true,
  'quiz-leaderboard': false,
  'quiz-open': true,
  quote: true,
  ranking: true,
  rating: true,
  scales: true,
  video: true,
  wordcloud: true,
};
