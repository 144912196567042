import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import type { SidebarPaneId } from '@mentimeter/editor-essentials/types';
import {
  ButtonBar,
  CommentsPaneDataHandler,
  PaneContainer,
  SidebarButton,
  Surface,
  useComments,
} from '@mentimeter/editor-properties-pane';
import {
  useActiveCanvasAreaStore,
  useActiveSidebarPaneStore,
} from '@mentimeter/editor-state';
import { TrackingContext, type ModuleId } from '@mentimeter/http-clients';
import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';
import { SidebarModalSolidIcon } from '@mentimeter/ragnar-visuals';
import { type UserT } from '@mentimeter/user';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { InteractiveCanvasTabBar } from '../InteractiveCanvasTabBar';
import { SidebarTabbedInterface } from '../SidebarTabbedInterface';
import { trackChangedSidebarTab } from '../tracking';
import {
  getSidebarDetails,
  isSidebarPaneOpen,
  isValidPaneId,
  useHandleSetActiveSidebarTab,
  useHandleSidebarTabClick,
} from '../utils';

export const SideBarViewOnly = ({
  user,
  workspaceId,
  activeQuestionId,
  activeQuestionModuleId,
  activeQuestionAdminKey,
  userIsViewCollaborator,
}: {
  user: UserT | null;
  workspaceId: number | null;
  activeQuestionId: string;
  activeQuestionModuleId: ModuleId;
  activeQuestionAdminKey: string;
  userIsViewCollaborator: boolean;
}) => {
  const { isMobile } = useDeviceChecker();
  const activeCanvasArea = useActiveCanvasAreaStore(
    (state) => state.activeCanvasArea,
  );
  const { data: workspace } = useWorkspace({ skip: !user });

  const { activeComments } = useComments(
    userIsViewCollaborator ? activeQuestionAdminKey : undefined,
  );
  const [activePane, setActivePane] = useActiveSidebarPaneStore();
  const { sidebarPanes, sidebarTabSurfaces } = getSidebarDetails();

  const isPaneOpen = isSidebarPaneOpen(activePane, sidebarPanes);
  const isShared = Boolean(workspaceId);
  const activeTeamMembers = workspace?.memberStats?.active || 0;
  const currentPlan = user?.features.type || 'registered';
  const roleInWorkspace = user?.role || 'user';

  const setPaneWithTracking = (paneId: SidebarPaneId) => {
    setActivePane(paneId);
    trackChangedSidebarTab(
      paneId !== 'closed'
        ? sidebarPanes[paneId as keyof typeof sidebarPanes]?.title
        : 'Closed sidebar',
      false,
      isShared,
      activeTeamMembers,
      currentPlan,
      roleInWorkspace,
      TrackingContext.ViewOnly,
    );
  };

  const handleSidebarTabClick = useHandleSidebarTabClick(
    setPaneWithTracking,
    sidebarPanes,
  );

  const handleSetActiveSidebarTab = useHandleSetActiveSidebarTab(
    setPaneWithTracking,
    sidebarPanes,
  );

  const getIconProps = {
    slideType: activeQuestionModuleId,
    canvasArea: activeCanvasArea,
    commentsCount: activeComments.length,
    isMobile,
  };

  return (
    <SidebarTabbedInterface
      activePane={activePane}
      handleSetActiveSidebarTab={handleSetActiveSidebarTab}
      isPaneOpen={isPaneOpen}
    >
      <InteractiveCanvasTabBar
        sidebarPanes={sidebarPanes}
        disabled={(tab) => tab !== 'comments' || !userIsViewCollaborator}
        disabledDescription={({ tab }) =>
          tab !== 'comments' || !userIsViewCollaborator
            ? VIEW_ONLY_DISABLED_MESSAGE
            : undefined
        }
        onClick={handleSidebarTabClick}
        getIconProps={getIconProps}
        sidebarTabSurfaces={sidebarTabSurfaces}
      />
      {activePane && isValidPaneId(activePane, sidebarPanes) && (
        <PaneContainer
          activeQuestionId={activeQuestionId}
          title="Comments"
          onDismiss={() => setPaneWithTracking('closed')}
        >
          {userIsViewCollaborator && (
            <CommentsPaneDataHandler
              trackingContext={TrackingContext.ViewOnly}
              visible
            />
          )}
        </PaneContainer>
      )}
      <ButtonBar>
        <Surface
          borderTopLeftRadius="none"
          borderTopRightRadius={[null, 'none']}
          borderBottomLeftRadius={['none', '2xl']}
          mt={undefined}
          flex="auto"
        >
          {isMobile && (
            <Box
              height="auto"
              borderRightWidth="1px"
              borderColor="borderWeak"
              borderStyle="solid"
              my="space6"
              mx="space2"
            />
          )}
          <SidebarButton
            key="templates"
            title="Templates"
            icon={
              <SidebarModalSolidIcon aria-hidden size={3} color="inherit" />
            }
            disabled={true}
            tooltipDescription={VIEW_ONLY_DISABLED_MESSAGE}
          />
        </Surface>
      </ButtonBar>
    </SidebarTabbedInterface>
  );
};
