import { HeaderIconButton } from '@mentimeter/editor-header/src/components/UI/HeaderIconButton';
import { type TrackingContext } from '@mentimeter/http-clients';
import {
  TooltipOld,
  useButtonState,
  type IconButtonProps,
} from '@mentimeter/ragnar-ui';
import { CopyIcon } from '@mentimeter/ragnar-visuals';
import { getRegionBySeriesId } from '@mentimeter/region';
import userCache from '@mentimeter/user';
import { useCallback } from 'react';
import { postDuplicateRequest } from '@core-api/view-only/view-only/{slide_deck_public_key}/duplicate';
import { trackUser } from '@api/tracking/client';
import { captureException } from '@sentry/nextjs';
import { MentiError } from '@mentimeter/errors/sentry';

export const CopyToAccount = ({
  trackingContext,
  buttonSize,
  seriesId,
}: {
  trackingContext: TrackingContext;
  buttonSize: IconButtonProps['size'];
  seriesId: string;
}) => {
  const [buttonState, setButtonState] = useButtonState();

  const copyPresentationToAccount = useCallback(async () => {
    setButtonState('loading');
    try {
      await fetch(
        postDuplicateRequest({
          slideDeckPublicKey: seriesId,
          region: userCache.region,
          resourceRegion: getRegionBySeriesId(seriesId),
          userAuth: userCache.getToken(),
        }),
      );

      setButtonState('success');
      setTimeout(() => setButtonState(undefined), 6000);
      trackUser({
        event: 'Duplicated presentations',
        properties: { context: trackingContext },
      });
    } catch (err) {
      setButtonState('error');
      setTimeout(() => setButtonState(undefined), 3000);
      captureException(
        new MentiError('Failed to copy presentation from view only header', {
          feature: 'copy-to-account',
          cause: err,
        }),
      );
    }
  }, [setButtonState, seriesId, trackingContext]);

  return (
    <>
      <HeaderIconButton
        id="copy-to-my-presentations-button"
        aria-label="Copy to your account"
        size={buttonSize}
        onClick={copyPresentationToAccount}
        variant="secondary"
        state={buttonState}
        disabled={buttonState !== undefined}
      >
        <CopyIcon />
      </HeaderIconButton>
      <TooltipOld
        referenceId="copy-to-my-presentations-button"
        description={
          buttonState === 'success' ? 'Copied' : 'Copy to My presentations'
        }
        placement="bottom-start"
      />
    </>
  );
};
