import { type Editor } from '@tiptap/core';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { Box, Button, useButtonState } from '@mentimeter/ragnar-ui';
import { useSearchParams } from '@mentimeter/next-navigation';
import { postCommentsRequest } from '@core-api/comments/comments';
import userCache from '@mentimeter/user';
import { useState, useRef, useCallback } from 'react';
import { patchCommentsRequest } from '@core-api/comments/comments/{id}';
import { useEditorSlideDeck } from '@mentimeter/editor-data-hooks';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { trackUser } from '@api/tracking/client';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useMentionableCollaborators } from '../data-handlers/MentionListDataHandler';
import {
  CommentTextEditor,
  type EditorHandler,
} from '../components/CommentTextEditor';
import { slideUpAnimation } from '../util/animations';
import { useRevalidateEditorComments } from '../hooks/useRevalidateEditorComments';
import { useCommentsTrackingContext } from '../components/CommentsTrackingContextProvider';

export const COMMENT_CHAR_LIMIT = 1000;

function getTaggedUsersCount(editor: Editor | undefined) {
  if (!editor) return 0;
  const json = editor?.getJSON();
  const uniqueMentions = new Set<string>();

  json?.content?.forEach(({ content = [] }) => {
    content.forEach((node) => {
      if (node.type === 'mention') {
        uniqueMentions.add(node.attrs?.id);
      }
    });
  });

  return uniqueMentions.size;
}

function getTaggableUsersCount(
  isWorkspacePresentation: boolean,
  workspace: any,
  collaborators: any[],
) {
  if (isWorkspacePresentation) {
    const { active = 0, memberLite = 0 } = workspace?.memberStats ?? {};
    return active - memberLite;
  }
  return collaborators?.length ?? 0;
}

export const CommentsControlDataHandler = ({
  onCancel,
  onSubmitComment,
  cta,
  placeholder,
  value,
  showControlButtonsInitially,
  commentId,
  parentCommentId,
  isEditingExisting = false,
  isOwner,
  minHeight,
}: {
  onCancel?: () => void;
  onSubmitComment?: () => void;
  cta: string;
  placeholder: string;
  value?: string;
  showControlButtonsInitially: boolean;
  commentId?: string;
  parentCommentId: string | null;
  isEditingExisting?: boolean;
  isOwner: boolean;
  minHeight?: number;
}) => {
  const seriesId = useActiveSeriesId();
  const { series } = useEditorSlideDeck(seriesId);
  const questionAdminKey =
    useSearchParams()?.get('question') || series?.questions[0]?.admin_key || '';
  const { revalidateEditorComments } = useRevalidateEditorComments(
    seriesId,
    questionAdminKey,
  );
  const [buttonState, setButtonState] = useButtonState();
  const [showControlButtons, setShowControlButtons] = useState(
    showControlButtonsInitially,
  );

  const editorRef = useRef<EditorHandler>(null);
  const shouldTrackOnFocus = useRef(true);

  const isWorkspacePresentation = series?.workspace_id !== null;

  const isReply = parentCommentId !== null;

  const { collaborators } = useMentionableCollaborators(seriesId);

  const { data: workspace } = useWorkspace();

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const context = useCommentsTrackingContext();

  const onFocus = () => {
    setShowControlButtons(true);

    // Track only if the user is focusing the editor for the first time
    if (shouldTrackOnFocus.current) {
      trackUser({
        event: isReply ? 'Clicked add reply' : 'Clicked add comment',

        properties: {
          context,
          placement: 'Comments pane',
        },
      });
    }
    shouldTrackOnFocus.current = false;
  };

  const onBlur = () => {
    shouldTrackOnFocus.current = true;
  };

  const resetTextAndFlags = useCallback((editor: Editor | undefined) => {
    if (!editor) return;
    setSubmitDisabled(true);
    setShowControlButtons(false);
    editor?.commands.clearContent();
  }, []);

  const taggableUsersCount = getTaggableUsersCount(
    isWorkspacePresentation,
    workspace,
    collaborators,
  );

  const handleSubmitComment = useCallback(
    async (editor: Editor | undefined) => {
      if (!editor) return;

      setButtonState('loading');
      const styledCommentText = JSON.stringify(editor.getJSON());

      try {
        let response;
        if (isEditingExisting && commentId) {
          response = await fetch(
            patchCommentsRequest({
              id: commentId,
              styledText: styledCommentText,
              region: userCache.region,
              userAuth: userCache.getToken(),
            }),
          );
        } else {
          response = await fetch(
            postCommentsRequest({
              styledText: styledCommentText,
              questionAdminKey,
              region: userCache.region,
              userAuth: userCache.getToken(),
              parentId: parentCommentId,
            }),
          );
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        trackUser({
          event: 'Comment written',
          properties: {
            context,
            placement: 'Comments tab',
            reply: isReply,
            owner: isOwner,
            'taggable users count': taggableUsersCount,
            'tagged users count': getTaggedUsersCount(editor),
          },
        });

        resetTextAndFlags(editor);
        await revalidateEditorComments();
        setButtonState(undefined);
        onSubmitComment?.();
        editor.commands.blur();
      } catch (error) {
        const err = new MentiError(
          `error ${isEditingExisting ? 'editing' : 'creating'} a comment`,
          {
            feature: 'comments-pane-editor',
            cause: error,
          },
        );
        captureException(err);
        setButtonState('error');
      }
    },
    [
      setButtonState,
      isEditingExisting,
      commentId,
      isReply,
      isOwner,
      taggableUsersCount,
      resetTextAndFlags,
      revalidateEditorComments,
      onSubmitComment,
      questionAdminKey,
      parentCommentId,
      context,
    ],
  );

  return (
    <>
      <CommentTextEditor
        ref={editorRef}
        autofocus={isReply || showControlButtonsInitially}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value ?? ''}
        placeholder={placeholder}
        showExpanded={showControlButtons}
        onKeyboardSubmit={handleSubmitComment}
        onUpdate={() => {
          setSubmitDisabled(editorRef.current?.getLength() === 0);
        }}
        onInitiateMention={() => {
          trackUser({
            event: 'Initiated user tagging',
            properties: {
              context,
              placement: 'Comments tab',
              reply: isReply,
              owner: isOwner,
              'taggable users count': taggableUsersCount,
            },
          });
        }}
        maxLength={COMMENT_CHAR_LIMIT}
        minHeight={minHeight}
      />
      <Box
        flexDirection="row"
        justifyContent="flex-end"
        mt={showControlButtons ? 'space4' : '-space4'}
        extend={() => ({
          ...slideUpAnimation(showControlButtons),
          opacity: showControlButtons ? 1 : 0,
          visibility: showControlButtons ? 'visible' : 'hidden',
        })}
      >
        <Button
          onClick={() => {
            resetTextAndFlags(editorRef.current?.getEditor());
            onCancel?.();
          }}
          mx="space2"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          state={buttonState}
          variant="primary"
          onClick={() => {
            handleSubmitComment(editorRef.current?.getEditor());
          }}
          disabled={submitDisabled}
        >
          {cta}
        </Button>
      </Box>
    </>
  );
};
