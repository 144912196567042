const VALID_SOURCES = new Set([
  'name-column-private',
  'name-column-sharedWithMe',
  'name-column-workspace',
  'name-column-shortcut-private',
  'name-column-shortcut-sharedWithMe',
  'name-column-shortcut-workspace',
  'grid-card-private',
  'grid-card-sharedWithMe',
  'grid-card-workspace',
  'request-result',
  'share-invite-modal',
  'share-modal',
]);

export const getSanitizedRefererSource = (inviteSource: string | null) => {
  if (!inviteSource) return null;

  return (
    [...VALID_SOURCES].find((validSource) =>
      inviteSource.includes(validSource),
    ) || null
  );
};
