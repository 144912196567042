import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { Image } from '../image';
import { ImageStretch } from '../image-stretch';

const MIN_IMAGE_SIZE = 2; // < 1px images create rendering issues
const PLACEHOLDER_WIDTH = 20; // px

export function getPlaceholderDimensions(
  originalWidth: number,
  originalHeight: number,
) {
  // Preserve aspect ratio compared to original image
  let placeholderWidth =
    originalWidth < PLACEHOLDER_WIDTH ? MIN_IMAGE_SIZE : PLACEHOLDER_WIDTH;
  let placeholderHeight = (placeholderWidth * originalHeight) / originalWidth;

  // Fix bug where placeholder height is a fraction of a pixel
  if (placeholderHeight < 1) {
    placeholderHeight = MIN_IMAGE_SIZE; // Greater than 1px
    placeholderWidth = (originalWidth * placeholderHeight) / originalHeight;
  }

  return { placeholderWidth, placeholderHeight };
}

export interface ImageProgressiveT {
  src: string;
  alt?: string;
  stretch?: boolean;
  cover?: boolean;
}
interface PropsT extends ImageProgressiveT {
  placeholderSrc: string;
  originalWidth: number;
  originalHeight: number;
}

const C = React.memo<PropsT>(
  ({
    src,
    placeholderSrc,
    alt = '',
    originalWidth,
    originalHeight,
    stretch = false,
    cover = false,
  }: PropsT) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [loaded, setLoaded] = React.useState(false);
    const [dimensions, setDimensions] = React.useState({
      width: MIN_IMAGE_SIZE,
      height: MIN_IMAGE_SIZE,
    });
    // If the image is proportionally more wide compared to the container than it is tall,
    // it should occupy its maximum width
    const isWideImage = React.useMemo(
      () =>
        dimensions.width / originalWidth < dimensions.height / originalHeight,
      [dimensions.width, dimensions.height, originalWidth, originalHeight],
    );

    // If the original image is small, it should not be stretched to 100% of the container size
    const width = React.useMemo(() => {
      if (isWideImage) {
        return originalWidth > dimensions.width || stretch
          ? '100%'
          : `${originalWidth}px`;
      }
      return undefined;
    }, [originalWidth, dimensions.width, stretch, isWideImage]);
    const height = React.useMemo(() => {
      if (isWideImage) {
        return undefined;
      }
      return originalHeight > dimensions.height || stretch
        ? '100%'
        : `${originalHeight}px`;
    }, [isWideImage, originalHeight, dimensions.height, stretch]);

    // Set image dimensions based on container's computed size
    React.useEffect(() => {
      const container = containerRef.current;
      if (container !== null) {
        const width = container.clientWidth;
        const height = container.clientHeight;
        setDimensions({ width, height });
      }
    }, []);

    return (
      <Box
        ref={containerRef}
        className={clsx(['items-center', 'justify-center', 'w-full', 'h-full'])}
      >
        <Image
          src={src}
          alt={alt}
          onLoad={() => setLoaded(true)}
          className={clsx([
            'w-full',
            'h-full',
            loaded ? 'inline' : 'hidden',
            cover ? 'object-cover' : 'object-fill',
          ])}
          style={{
            ...(width && { width }),
            ...(height && { height }),
          }}
        />

        {!loaded && (
          <Image
            src={placeholderSrc}
            alt={alt}
            className={clsx(['w-full', 'h-full'])}
            style={{
              ...(width && { width }),
              ...(height && { height }),
            }}
          />
        )}
      </Box>
    );
  },
);

const ImageProgressive = ({
  src,
  alt = '',
  stretch = false,
  cover = false,
}: ImageProgressiveT) => {
  const placeholderSrc = new URL(src);

  const originalWidth = parseInt(placeholderSrc.searchParams.get('w')!, 10);
  const originalHeight = parseInt(placeholderSrc.searchParams.get('h')!, 10);

  // Some images don't have dimension params (i.e., SVG), parseInt returns NaN in these cases
  if (isNaN(originalWidth) || isNaN(originalHeight)) {
    if (stretch) {
      return <ImageStretch src={src} alt={alt} />;
    } else {
      return cover ? (
        <Image
          src={src}
          alt={alt}
          className={clsx(['object-cover', 'w-full', 'h-full'])}
        />
      ) : (
        <Image src={src} alt={alt} />
      );
    }
  }

  const { placeholderWidth, placeholderHeight } = getPlaceholderDimensions(
    originalWidth,
    originalHeight,
  );

  // Requesting an image with smaller dimensions loads more quickly
  placeholderSrc.searchParams.set('w', placeholderWidth.toString());
  placeholderSrc.searchParams.set('h', placeholderHeight.toString());

  return (
    <C
      src={src}
      placeholderSrc={placeholderSrc.href}
      alt={alt}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      stretch={stretch}
      cover={cover}
    />
  );
};

export { ImageProgressive };
