import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';
import type { SlideDeck as VotingSlideDeck } from '@mentimeter/voting-schema/api-types-overrides';
import type { Series } from '@mentimeter/http-clients';
import { PARTICIPATION_MODE_SURVEY } from '../../../constants';

export const participationModeToSeriesPaceMode = (
  participationSettings:
    | EditorSlideDeck['participationSettings']
    | PresentationSlideDeck['participationSettings']
    | VotingSlideDeck['participationSettings']
    | undefined,
): Series['pace']['mode'] => {
  return participationSettings?.participationMode === PARTICIPATION_MODE_SURVEY
    ? 'audience'
    : 'presenter';
};
