import { trackUser } from '@api/tracking/client';
import { getSanitizedRefererSource } from '@mentimeter/editor-tracking';
import type { TrackingContext } from '@mentimeter/http-clients';
import { tracking } from '@mentimeter/http-clients';
import { getVisitorToken } from '@mentimeter/splitio';
import type { UserT } from '@mentimeter/user';
import type { CollaboratorStatus } from 'utils/constants';

interface SeriesData {
  id: string;
  has_results: boolean;
  co_edited: boolean;
  slideCount?: number;
}

export const trackViewOnlyEditor = async (
  user: UserT | null,
  trackingContext: TrackingContext,
  series: SeriesData,
  collaboratorStatus: CollaboratorStatus,
  inviteSource: string | null,
) => {
  const trackingPayload = {
    event: 'Viewed edit view',
    properties: {
      role: 'Collaborator',
      context: trackingContext,
      'workspace role': user?.role,
      'series id': series.id,
      'has results': series.has_results,
      'shared presentation': series.co_edited,
      permission: collaboratorStatus,
      'invite source': getSanitizedRefererSource(inviteSource),
      'slide count': series.slideCount,
    },
  };

  if (user) return trackUser(trackingPayload);
  const visitorToken = await getVisitorToken();
  if (!visitorToken) return;

  return tracking().trackVisitor(trackingPayload, visitorToken as string);
};

export const trackClickedSlideThumbnail = async (
  user: UserT | null,
  trackingContext: TrackingContext,
  series: SeriesData,
  collaboratorStatus: CollaboratorStatus,
) => {
  const trackingPayload = {
    event: 'Clicked slide thumbnail',
    properties: {
      role: 'Collaborator',
      context: trackingContext,
      'workspace role': user?.role ?? '',
      'series id': series.id,
      'has results': series.has_results,
      'shared presentation': series.co_edited,
      permission: collaboratorStatus,
    },
  };

  if (user) return trackUser(trackingPayload);
  const visitorToken = await getVisitorToken();
  if (!visitorToken) return;

  return tracking().trackVisitor(trackingPayload, visitorToken as string);
};
