import type { Question } from '@mentimeter/http-clients';
import type { NumberStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionNumberProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'number' }
  >;

  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question':
      return staticContents.number;
    case 'question_description':
      return staticContents.description;
    default:
      return undefined;
  }
};
