import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { useIsSlideDeckMigrated } from '@mentimeter/compatibility/migration-status';
import { reactionsResultsCacheKey } from '@mentimeter/core-hooks';
import { usePresentationReactionResults } from '@mentimeter/presentation-data-hooks/slide';
import type { QuestionWithSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import { usePresentationRealtimeStore } from '@mentimeter/realtime';

const POLLING_INTERVAL = 10000;

export const usePresentationReactionResultsCompatibility = (
  question: QuestionWithSlide | undefined,
) => {
  const slideDeckPublicKey = useActiveSeriesId();
  const connectionState = usePresentationRealtimeStore(
    (state) => state.connectionState,
  );

  let legacyQuestionIdOrSlideId = question?.id;
  const isMigrated = useIsSlideDeckMigrated();
  if (question?.slideId) {
    legacyQuestionIdOrSlideId = question.slideId;
  }

  const { data } = usePresentationReactionResults(
    {
      slideDeckPublicKey,
      legacyQuestionIdOrSlideId,
      session: 'current',
      isMigrated,
    },
    {
      getReactionResultsCacheKey: reactionsResultsCacheKey,
      swrConfig: {
        refreshInterval:
          connectionState === 'failed' || connectionState === 'disconnected'
            ? POLLING_INTERVAL
            : 0,
      },
    },
  );

  return data;
};
