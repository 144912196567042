import type { Question } from '@mentimeter/http-clients';
import type { WordcloudStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getWordcloudStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );
  const content: WordcloudStaticContentSchema = {
    version: 'v1',
    type: 'word-cloud',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      textSizeOffset,
    },
  };

  return content;
};
