import React, { forwardRef, useMemo } from 'react';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { type CollaboratorType } from '@mentimeter/http-clients';
import { useSeriesCollaborators } from '@mentimeter/core-hooks';
import type { SeriesCollaborator } from '@core-api/collaboration/types/response';
import { MentionList } from '../components/MentionList';
import type {
  KeyDownHandler,
  MentionSuggestion,
  MentionsListDataHandlerProps,
} from './types';

const VALID_COLLABORATOR_TYPES: CollaboratorType[] = [
  'user',
  'folder-collaborator',
];

export const useMentionableCollaborators = (seriesId: string) => {
  const {
    collaborators: data,
    isLoading,
    error,
  } = useSeriesCollaborators(seriesId);

  const collaborators = useMemo(
    () =>
      data?.filter(
        (collaborator: SeriesCollaborator) =>
          collaborator.id &&
          VALID_COLLABORATOR_TYPES.includes(collaborator.type),
      ) || [],
    [data],
  );

  return { collaborators, isLoading, error };
};

export const MentionListDataHandler = forwardRef<
  KeyDownHandler,
  MentionsListDataHandlerProps
>(({ query, command }, ref) => {
  const seriesId = useActiveSeriesId();
  const { collaborators, isLoading, error } =
    useMentionableCollaborators(seriesId);

  const items: MentionSuggestion[] = collaborators
    .filter(({ entity }) =>
      entity.name.toLowerCase().startsWith(query.toLowerCase()),
    )
    .map((collaborator) => ({
      id: collaborator.entity.id,
      name: collaborator.entity.name,
      email: collaborator.entity.email,
      profilePictureUrl: collaborator.entity.profilePictureUrl,
      access: collaborator.access,
    }));

  return (
    <MentionList
      command={command}
      items={items}
      isLoading={isLoading}
      isError={Boolean(error)}
      ref={ref}
    />
  );
});
