import type { Question } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as ViewOnlyQuestionWithSlide,
  Slide as ViewOnlySlide,
} from '@mentimeter/view-only-schema/api-types-overrides';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { getCommonSlideCompatibilityHandler } from '../../common/get-common-slide-compatibility-handler';
import { viewOnlySlidePropertyKeys } from './constants';
import { mapViewOnlySlidePropertyToQuestionProperty } from './mapViewOnlySlidePropertyToQuestionProperty';

export function createViewOnlySlideCompatibilityLayer(
  target: Question | ViewOnlySlide,
): ViewOnlyQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as ViewOnlyQuestionWithSlide;
  }

  return new Proxy(
    target,
    getCommonSlideCompatibilityHandler(
      viewOnlySlidePropertyKeys,
      mapViewOnlySlidePropertyToQuestionProperty,
    ),
  ) as ViewOnlyQuestionWithSlide;
}
