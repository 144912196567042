import type { Question } from '@mentimeter/http-clients';
import type { LeaderboardStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { isContentType } from '../../utils/is-content-type';
import { MismatchedSlideTypePropertyError } from '../errors/mismatched-slide-type-property-error';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

const staticContentSchemaType: StaticContentSchema['type'] = 'leaderboard';

export const getQuestionLeaderboardProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent;

  if (
    isContentType<StaticContentSchema>(staticContents, staticContentSchemaType)
  ) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'question':
        return target.title ?? '';
      case 'question_styled':
        return staticContents.styledTitle ?? {};
      default:
        return undefined;
    }
  }

  throw new MismatchedSlideTypePropertyError(
    staticContentSchemaType,
    target.staticContent.type,
  );
};
