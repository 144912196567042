import { useEditorRouter } from '@mentimeter/editor-essentials/hooks';
import type { Theme } from '@mentimeter/http-clients';
import {
  OverviewList,
  OverviewListItemWrapper,
  type OverviewListItemType,
} from '@mentimeter/editor-overview-ui';
import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { useKeyboardHandler } from '@mentimeter/editor-essentials/utils';

export const OverviewListViewOnly = ({
  seriesTheme,
  activeQuestionId,
  overviewListItems,
  userIsViewOrCommentCollaborator,
  onClickSlideThumbnail,
  onClickCommentIcon,
}: {
  seriesTheme: Theme;
  activeQuestionId: string;
  overviewListItems: OverviewListItemType[];
  userIsViewOrCommentCollaborator: boolean;
  onClickSlideThumbnail: (slideId: string) => void;
  onClickCommentIcon: () => void;
}) => {
  const { navigateToSlide } = useEditorRouter();

  const { isSmallDevice: mobile } = useDeviceChecker();
  useKeyboardHandler();

  return (
    <OverviewList
      seriesBgColor={seriesTheme.background_color}
      seriesTextColor={seriesTheme.text_color}
      items={overviewListItems}
      selectedItemsIds={[]}
      activeItemId={activeQuestionId}
      isDragDisabled={true}
      onDisableKeyboardNavigation={() => {}}
      onDragEnd={() => {}}
      renderItem={({ item, selected, active, index }) => {
        return (
          <OverviewListItemWrapper
            key={item.id}
            active={active}
            index={index}
            item={item}
            mobile={mobile}
            selected={selected}
            theme={seriesTheme}
            onClick={(_e, itemId) => {
              onClickSlideThumbnail(itemId);
              navigateToSlide(itemId);
            }}
            userIsViewCollaborator={userIsViewOrCommentCollaborator}
            onClickCommentIcon={onClickCommentIcon}
          />
        );
      }}
    />
  );
};
