import {
  useActiveQuestionId,
  useAppRouterIds,
} from '@mentimeter/core-hooks/use-ids';
import { getSlideById } from '@mentimeter/editor-essentials/calculations';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { useEditorRouter } from '@mentimeter/editor-essentials/hooks';
import { KeyboardHandlerProvider } from '@mentimeter/editor-essentials/utils';
import {
  EditorOverviewDataHandler,
  OverviewListViewOnly,
} from '@mentimeter/editor-overview-data-handlers';
import { useActiveSidebarPaneStore } from '@mentimeter/editor-state';
import { trackClickedCommentIcon } from '@mentimeter/editor-tracking';
import type { TrackingContext } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as ViewOnlyQuestionWithSlide,
  SeriesWithSlideDeck,
} from '@mentimeter/view-only-schema/api-types-overrides';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, TooltipOld } from '@mentimeter/ragnar-ui';
import { ConfigProvider } from 'features/core/presentation/shared/config';
import { ViewOnly } from '@mentimeter/sfinx';
import { type UserT } from '@mentimeter/user';
import { EditorThemeProvider } from 'features/core/edit_view/application/EditorThemeProvider';
import {
  ADD_SLIDE_BUTTON_ID,
  AddSlideButtonComponent,
} from 'features/core/editor/Header/components/AddSlideButtonComponent';
import { HeaderViewOnly } from 'features/core/editor/Header/view-only/HeaderViewOnly';
import { SideBarViewOnly } from 'features/core/editor/Sidebar/view-only/SideBarViewOnly';
import { useEffect } from 'react';
import {
  COLLABORATOR_STATUS_COMMENT,
  COLLABORATOR_STATUS_VIEW,
  type CollaboratorStatus,
} from 'utils/constants';
import { Intercom } from 'utils/Intercom';
import { useRegisterViewSeries } from 'features/core/edit_view/application/shared/use-register-view-series';
import { AspectRatioContainer } from '@mentimeter/editor-canvas';
import { EditorCanvasAreaSlideLayout } from 'features/core/edit_view/application/EditorCanvasAreaSlideLayout';
import { setupRegion } from 'lib/http/region-setup';
import { CanvasAreaLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasAreaLayout';
import { CanvasContentLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasContentLayout';
import { CanvasLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasLayout';
import { CanvasSidebarLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasSidebarLayout';
import { MainLayout } from '../../../(collaborator)/(editor)/editor-layout-components/MainLayout';
import { PageLayout } from '../../../(collaborator)/(editor)/editor-layout-components/PageLayout';
import { PanelsLayout } from '../../../(collaborator)/(editor)/editor-layout-components/PanelsLayout';
import { PresentationContainer } from '../../PresentationContainer';
import { trackClickedSlideThumbnail } from '../../trackViewOnlyEditor';

export const ViewOnlyPage = ({
  user,
  series,
  questions = [],
  collaboratorStatus,
  questionId,
  trackingContext,
}: {
  user: UserT | null;
  series: SeriesWithSlideDeck;
  questions: Array<ViewOnlyQuestionWithSlide>;
  collaboratorStatus: CollaboratorStatus;
  questionId: string;
  trackingContext: TrackingContext;
}) => {
  const { navigateToSlide } = useEditorRouter();
  const activeQuestionId = useActiveQuestionId();

  const [activeQuestion, activeQuestionIndex] = getSlideById(
    activeQuestionId,
    questions,
  );

  useRegisterViewSeries(series.id, Boolean(user));

  const [, setActiveSidebarPane] = useActiveSidebarPaneStore();
  const shouldSidebarBeInitiallyClosed = useMatch({ lessThan: 3 });
  useEffect(() => {
    if (shouldSidebarBeInitiallyClosed) {
      setActiveSidebarPane('closed');
    }
  }, [shouldSidebarBeInitiallyClosed, setActiveSidebarPane]);
  setupRegion(series.id);
  useAppRouterIds({ seriesId: series.id, questionId });

  const userIsViewCollaborator =
    collaboratorStatus === COLLABORATOR_STATUS_VIEW;
  const userIsCommentCollaborator =
    collaboratorStatus === COLLABORATOR_STATUS_COMMENT;

  return (
    <EditorThemeProvider>
      <Intercom />
      <KeyboardHandlerProvider
        questionIds={questions.map((question) => question.id)}
        activeQuestionIndex={activeQuestionIndex}
        activateQuestion={(questionId: string) => {
          trackClickedSlideThumbnail(
            user,
            trackingContext,
            series,
            collaboratorStatus,
          );
          navigateToSlide(questionId);
        }}
      >
        <PageLayout
          elementId="viewonly-editor-container"
          header={
            <HeaderViewOnly
              seriesId={series.id}
              seriesName={series.name}
              seriesWorkspaceId={series.workspace_id}
              trackingContext={trackingContext}
              userIsCollaborator={
                userIsCommentCollaborator || userIsViewCollaborator
              }
              user={user}
              badgeLabel={userIsCommentCollaborator ? 'Comment' : 'View'}
            />
          }
          content={
            <MainLayout>
              <PanelsLayout>
                <EditorOverviewDataHandler
                  amountOfSlides={questions.length}
                  activeSlideIndex={activeQuestionIndex}
                >
                  <Box width="100%" p="space4" alignItems="stretch">
                    <Box alignItems="inherit" gap={2} pl="space3">
                      <TooltipOld
                        placement="right"
                        referenceId={ADD_SLIDE_BUTTON_ID}
                        description={VIEW_ONLY_DISABLED_MESSAGE}
                      />
                      <AddSlideButtonComponent
                        disabled={true}
                        handleClickAddSlideButton={() => {}}
                        showAddSlidePopover={false}
                        buttonExtend={{}}
                      />
                    </Box>
                  </Box>
                  <OverviewListViewOnly
                    seriesTheme={series.theme}
                    activeQuestionId={activeQuestionId}
                    overviewListItems={questions}
                    userIsViewOrCommentCollaborator={
                      userIsViewCollaborator || userIsCommentCollaborator
                    }
                    onClickSlideThumbnail={() => {
                      trackClickedSlideThumbnail(
                        user,
                        trackingContext,
                        series,
                        collaboratorStatus,
                      );
                    }}
                    onClickCommentIcon={() => {
                      setActiveSidebarPane('comments');
                      trackClickedCommentIcon({
                        role: 'Collaborator',
                        context: trackingContext,
                        seriesId: series.id,
                        isSharedPresentation: series.co_edited,
                        hasResults: series.has_results,
                        permission: collaboratorStatus,
                      });
                    }}
                  />
                </EditorOverviewDataHandler>
                <CanvasSidebarLayout>
                  <CanvasAreaLayout>
                    <CanvasLayout>
                      <CanvasContentLayout>
                        <AspectRatioContainer>
                          <ConfigProvider>
                            <PresentationContainer
                              series={series}
                              questionId={questionId}
                              mode="view"
                            >
                              <EditorCanvasAreaSlideLayout
                                isCanvasInteractive
                                isSlideActiveAndSidebarClosed
                              >
                                <ViewOnly />
                              </EditorCanvasAreaSlideLayout>
                            </PresentationContainer>
                          </ConfigProvider>
                        </AspectRatioContainer>
                      </CanvasContentLayout>
                    </CanvasLayout>
                  </CanvasAreaLayout>
                  {activeQuestion && (
                    <SideBarViewOnly
                      user={user}
                      workspaceId={series.workspace_id}
                      activeQuestionId={activeQuestion.id}
                      activeQuestionModuleId={activeQuestion.module_id}
                      activeQuestionAdminKey={activeQuestion.admin_key}
                      userIsViewCollaborator={userIsCommentCollaborator}
                    />
                  )}
                </CanvasSidebarLayout>
              </PanelsLayout>
            </MainLayout>
          }
        />
      </KeyboardHandlerProvider>
    </EditorThemeProvider>
  );
};
