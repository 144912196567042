import type { StaticContentSchema } from '@mentimeter/schema/static-content';
import type { Question } from '@mentimeter/http-clients';
import type { CompatibilitySlide } from '../../compatibility-types';

export const getQuestionQuizChoicesProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'quiz-choice' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  return undefined;
};
