import { getSeriesRequest } from '@core-api/editor/editor/series/{series_id}';
import { createEditorSeriesCompatibilityLayer } from '@mentimeter/compatibility/editor';
import { seriesCacheKey, useData } from '@mentimeter/core-hooks';
import type { SeriesWithSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import { useCompatibilitySeriesFetcher } from '@mentimeter/migration-utils';
import { useCallback, useMemo } from 'react';
import { type SWRConfiguration } from 'swr';
import invariant from 'tiny-invariant';

interface UseEditorSeriesOptions {
  swrConfig?: SWRConfiguration;
  getSeriesCacheKey?: (seriesId: string) => string | Array<string>;
}

interface UseEditorSlideDeckReturn {
  /**
   * **[Deprecated]** Use {@link UseEditorSlideDeckReturn.slideDeck useEditorSlideDeck().slideDeck} instead.
   * @deprecated
   */
  series: SeriesWithSlideDeck | undefined;
  slideDeck: SeriesWithSlideDeck | undefined;
  error: any;
  revalidate: () => Promise<{
    responseForBackwardCompat: SeriesWithSlideDeck | undefined;
  }>;
  /**
   * **[Deprecated]** Use {@link UseEditorSlideDeckReturn.lazyGetSlideDeck useEditorSlideDeck().lazyGetSlideDeck} instead.
   * @deprecated
   */
  lazyGetSeries: () => SeriesWithSlideDeck | undefined;
  lazyGetSlideDeck: () => SeriesWithSlideDeck | undefined;
}

/**
 * Fetches a "series with a slide deck" with backwards compatibility to our legacy domain model.
 * @remarks Requires an **authenticated client** and it should **only** be used within the editor app.
 */
export const useEditorSlideDeck = (
  seriesId: string,
  options: UseEditorSeriesOptions = {},
): UseEditorSlideDeckReturn => {
  invariant(seriesId, 'useEditorSlideDeck(SWR): seriesId is required');
  const getCacheKey = options?.getSeriesCacheKey ?? seriesCacheKey;
  const cacheKey = getCacheKey(seriesId);
  const shouldUseNewEndpoints = true;

  const fetcher = useCompatibilitySeriesFetcher<SeriesWithSlideDeck>(
    seriesId,
    getSeriesRequest,
    shouldUseNewEndpoints,
  );

  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    focusThrottleInterval: 1000 * 60 * 2,
    ...options.swrConfig,
  };

  const { data, error, revalidate, lazyData } = useData<SeriesWithSlideDeck>(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    swrConfig,
  );

  const wrappedLazyData = useCallback(() => {
    const slideDeck = lazyData();
    return createEditorSeriesCompatibilityLayer(slideDeck);
  }, [lazyData]);

  return useMemo(() => {
    const slideDeck = createEditorSeriesCompatibilityLayer(data);
    return {
      slideDeck,
      series: slideDeck,
      error,
      revalidate,
      lazyGetSlideDeck: wrappedLazyData,
      lazyGetSeries: wrappedLazyData,
    };
  }, [data, error, revalidate, wrappedLazyData]);
};
