import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import type { StaticContentSchema } from '@mentimeter/schema/static-content';
import type { CompatibilitySlide } from '../../compatibility-types';
import { correctAreaToQuestionCorrectArea } from '../converters/interactive-content';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionPinOnImageProperty = (
  target: CompatibilitySlide,
  prop: keyof QuestionWithSlide,
) => {
  const interactiveContents = target.interactiveContents?.[0];
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'pin-on-image' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<QuestionWithSlide, CommonQuestionProperty>) {
      case 'correct_area':
        return correctAreaToQuestionCorrectArea(interactiveContents.correctArea)
          ?.correct_area;

      case 'show_correct':
        return correctAreaToQuestionCorrectArea(interactiveContents.correctArea)
          ?.show_correct;
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slideId);
};
